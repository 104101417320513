import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Providers } from './app/providers';
import { AppRoutes } from './app/app-routes';

console.log("NODE ENV v2024-09-15 ", process.env.NODE_ENV)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Providers>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </Providers>
);
