import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

export const app: FirebaseApp = (() => {
    return initializeApp({
        apiKey: process.env.NX_FIREBASE_API_KEY, // "AIzaSyDjDLYRdraVPdvJDV6GjpWfaiRM0XJHrys",
        authDomain: process.env.NX_FIREBASE_AUTH_DOMAIN, // "chipleadercoaching-webapp.firebaseapp.com",
        databaseURL: process.env.NX_FIREBASE_DATABASE_URL, // "https://chipleadercoaching-webapp.firebaseio.com",
        projectId: process.env.NX_FIREBASE_PROJECT_ID, // "chipleadercoaching-webapp",
        storageBucket: process.env.NX_FIREBASE_STORAGE_BUCKET, // "chipleadercoaching-webapp.appspot.com",
        messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID, // "446390346165",
        appId: process.env.NX_FIREBASE_APP_ID, // "1:446390346165:web:30ad9553d10dbb757ff9fc",
        measurementId: process.env.NX_FIREBASE_MEASUREMENT_ID, // "G-MJW9SW2Z64",
    });
})();

export const firestore = getFirestore(app);
