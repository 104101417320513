import { LibraryAuthor, LibraryVideoTag, User } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'

export interface LibraryState {
  video_tags?: LibraryVideoTag[];
  library_authors?: LibraryAuthor[];
}

const defaultState: LibraryState = {}

export const librarySlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    set_video_tags: (state: LibraryState, action: { payload: LibraryVideoTag[] }) => {
      state.video_tags = action.payload;
    },
    set_library_authors: (state: LibraryState, action: { payload: LibraryAuthor[] }) => {
      state.library_authors = action.payload;
    },
    clear_library: (state: LibraryState) => {
      state = defaultState;
    }
  },
})

export const {
  set_video_tags,
  set_library_authors,
  clear_library,
} = librarySlice.actions
export const libraryReducer = librarySlice.reducer