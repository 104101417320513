import moment from "moment";
import { useState } from "react";
import { historyService } from "../services/history.service";
import { EarningHistory } from "@prisma/client";
import { EarningHistoryWithUser } from "@clc-v2/utilities";
import lodash from "lodash";

const format = "YYYY-MM-DD HH:mm:ss";

export const PlayingHistory = () => {
  const [from, set_from] = useState<string>(moment().format(format));
  const [to, set_to] = useState<string>(moment().format(format));
  const [email, set_email] = useState<string>('');
  const [earning_history, set_earning_history] = useState<lodash.Dictionary<EarningHistoryWithUser[]>
  >({});
  const filter_handler = () => {
    historyService.getEarningHistoryRank(from, to, email).then((_earning_history) => {
      const grouped_earning = lodash.groupBy(_earning_history, (el) => el.user.email);
      set_earning_history(grouped_earning);
    }).catch((err_msg: string) => {
      alert(err_msg);
    })
  }
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <input type="datetime-local" value={from} onChange={(e) => set_from(moment(e.target.value).format(format))} />
        <input type="datetime-local" value={to} onChange={(e) => set_to(moment(e.target.value).format(format))} />
        <input type="email" value={email} onChange={(e) => set_email(e.target.value)} placeholder="User email" />
        <button className="text-white" onClick={filter_handler}>Filter</button>
      </div>
      {Object.keys(earning_history).map((user_email, id) => (
        <div className="w-full flex flex-row gap-4 border-b">
          <p className="w-80 shrink-0 grow-0 text-white">{user_email}</p>
          <div className="w-full flex flex-col gap-4 border-l border-r">
            {earning_history[user_email].map((earning) => (
              <div className="w-full flex items-start gap-6 px-4">
                <div className="w-80 shrink-0 grow-0 text-white">{earning.id}</div>
                <div className="w-40 shrink-0 grow-0 text-white">{earning.date}</div>
                <div className="w-20 shrink-0 grow-0 text-white">{earning.hand_number}</div>
                <div className="w-16 shrink-0 grow-0 text-white">{earning.result ? "Correct" : "Wrong"}</div>
                <div className="text-white">{earning.type}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}