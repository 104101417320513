import { Level, RewardPoints } from "@prisma/client";
import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";

class PointSystemService {
  updateRewardByPointType(type: string, reward: number) {
    return new Promise<RewardPoints>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/point-system/reward-point`, {
        type, reward
      }).then((res: { data: RewardPoints }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  getRewardPoints() {
    return new Promise<RewardPoints[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/point-system/reward-point`).then((res: { data: RewardPoints[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  addLevel(data: Omit<Level, 'id'>) {
    return new Promise<Level>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/point-system/levels`, data).then((res: { data: Level }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  updateLevel(data: Level) {
    return new Promise<Level>((resolve, reject) => {
      axios.patch(`${API_BACKEND_ENDPOINT}/point-system/levels?id=${data.id}`, data).then((res: { data: Level }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  getLevels() {
    return new Promise<Level[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/point-system/levels`).then((res: { data: Level[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  deleteLevel(id: string) {
    return new Promise<Level>((resolve, reject) => {
      axios.delete(`${API_BACKEND_ENDPOINT}/point-system/levels?id=${id}`).then((res: { data: Level }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
}

export const pointSystemService = new PointSystemService();
