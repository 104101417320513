import { Tags } from "@clc-v2/uis";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { useEffect, useState } from "react";
import { libraryService } from "../../services/library.service";
import { set_video_tags } from "../../stores/library";
import { IcAdd } from "@clc-v2/icons";
import Modal from "react-responsive-modal";
import { LibraryVideoTag } from "@prisma/client";
import { toast } from "react-toastify";

export const VideoTagsPanel = () => {
  const dispatch = useAppDispatch();
  const { video_tags } = useAppSelector((state) => state.library);
  const [open_new_video_tag, set_open_new_video_tag] = useState<boolean>(false);
  const [new_video_tag_name, set_new_video_tag_name] = useState<string>('');
  const [open_edit_video_tag, set_open_edit_video_tag] = useState<boolean>(false);
  const [edit_video_tag, set_edit_video_tag] = useState<LibraryVideoTag>({
    id: '',
    name: '',
  });
  const register_new_video_tag = () => {
    libraryService.registerNewVideoTag(new_video_tag_name).then((_new_video_tag) => {
      dispatch(
        set_video_tags([...(video_tags ?? []), _new_video_tag])
      )
      toast.success(`New Tag (${_new_video_tag.id}) is registered`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_new_video_tag_name('');
    });
  }
  const update_edit_video_tag = () => {
    libraryService.updateVideoTag(edit_video_tag).then((_updated_video_tag) => {
      dispatch(
        set_video_tags((video_tags ?? []).map((video_tag) => video_tag.id === _updated_video_tag.id ? _updated_video_tag : video_tag))
      )
      toast.success(`Video Tag (${_updated_video_tag.id}) is updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_new_video_tag_name('');
    });
  }
  const delete_video_tag = (video_tag_id: string) => {
    const yes = confirm(`Do you want to delete video tag ${video_tag_id}?`);
    if (yes) {
      libraryService.deleteVideoTag(video_tag_id).then((_removed_video_tag) => {
        dispatch(
          set_video_tags((video_tags ?? []).filter((_video_tag) => _video_tag.id !== _removed_video_tag.id))
        )
        set_open_edit_video_tag(false);
        toast.warn(`Video Tag (${_removed_video_tag.id}) is deleted`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  useEffect(() => {
    libraryService.getAllVideoTags().then((_video_tags) => {
      dispatch(
        set_video_tags(_video_tags)
      )
    }).catch((err_msg: string) => {
      console.error(err_msg);
    });
  }, []);
  return (
    <div className="w-full flex items-start gap-6 px-4 py-4 border-b">
      <div className="w-40 grow-0 shrink-0 text-white flex items-center gap-2">
        <p>Video Tags({(video_tags ?? []).length})</p>
        <div className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" onClick={() => set_open_new_video_tag(true)}>
          <IcAdd />
        </div>
      </div>
      <div>
        {(video_tags ?? []).length > 0 ? (
          <Tags tags={(video_tags ?? []).map((tag) => tag).sort((a, b) => a.name > b.name ? 1 : -1)} onTagHandler={(_tag) => {
            set_edit_video_tag(_tag);
            set_open_edit_video_tag(true);
          }} />
        ) : (
          <span className="text-white">No video tags</span>
        )}
      </div>
      <Modal open={open_new_video_tag} onClose={() => set_open_new_video_tag(false)} center>
        <h2>Register New Tag</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2">
          <label className="w-32 text-black" htmlFor="New Video Tag Name">Video Tag Name</label>
          <input className="border p-2" type="text" id="New Video Tag Name" value={new_video_tag_name} onChange={(e) => set_new_video_tag_name(e.target.value)} onKeyUp={(e) => {
            if (e.key === 'Enter') {
              register_new_video_tag();
            }
          }} />
        </div>
        <div className="flex justify-between items-center mt-6">
          <button onClick={() => set_open_new_video_tag(false)}>Cancel</button>
          <button onClick={() => register_new_video_tag()}>Register</button>
        </div>
      </Modal>
      <Modal open={open_edit_video_tag} onClose={() => set_open_edit_video_tag(false)} center>
        <h2>Update Tag {edit_video_tag.id}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2">
          <label className="w-32 text-black" htmlFor="Edit Video Tag Name">Video Tag Name</label>
          <input className="border p-2" type="text" id="Edit Video Tag Name" value={edit_video_tag.name} onChange={(e) => set_edit_video_tag((_prev) => ({ ..._prev, name: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-6">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_video_tag(false)}>Cancel</button>
          <div className="flex items-center gap-4">
            <button className="px-3 py-1 bg-red-500 text-white" onClick={() => delete_video_tag(edit_video_tag.id)}>Delete</button>
            <button className="px-3 py-1 bg-green text-white" onClick={() => update_edit_video_tag()}>Update</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}