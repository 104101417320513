import axios, { type AxiosError } from 'axios';
import { API_BACKEND_ENDPOINT } from '../config';
import { Glossary, Lesson, Question, Topic } from '@prisma/client';
import { ILessonWithTopicUID, ITopic, TopicWithLesson } from '@clc-v2/utilities';
import { IQuestion } from 'libs/uis/src/lib/poker-player/interfaces/question.interface';

class TopicLessonQuestionService {
    async getAllTopics() {
        return new Promise<Array<TopicWithLesson>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic`).then((res: { data: TopicWithLesson[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadTopicCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadTopicBackupV1CSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic/upload-from-v1-db-backup`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllLessons() {
        return new Promise<Array<Lesson>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson`).then((res: { data: Lesson[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadLessonCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadLessonBackupV1CSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/upload-from-v1-db-backup`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    /**
     * @deprecated
     * @param lesson_uid 
     * @returns 
     */
    async getAllQuestionsOfLesson(lesson_uid: string) {
        return new Promise<Array<Question>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/all-questions-of-lesson?lesson_uid=${lesson_uid}`).then((res: { data: Question[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllQuestionsByPage(page: number, hand_number?: number) {
        return new Promise<Array<Question>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/all-questions-by-page?page=${page}&hand_number=${hand_number}`).then((res: { data: Question[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllGlossaries() {
        return new Promise<Array<Glossary>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary`).then((res: { data: Glossary[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateEditTopic(topic: ITopic) {
        return new Promise<Topic>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic/${topic.uid}`, {
                uid: topic.uid,
                name: topic.name,
                mastered_require: topic.mastered_require,
            }).then((res: { data: Topic }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewTopic(topic: ITopic) {
        return new Promise<Topic>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic`, {
                uid: topic.uid,
                name: topic.name,
                mastered_require: topic.mastered_require,
            }).then((res: { data: Topic }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeTopic(topic_uid: string) {
        return new Promise<Topic>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/topic-lesson-question/topic?uid=${topic_uid}`).then((res: { data: Topic }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateEditLesson(lesson: ILessonWithTopicUID) {
        return new Promise<Lesson>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson/${lesson.uid}`, {
                uid: lesson.uid,
                name: lesson.name,
                description: lesson.description,
                order: lesson.order,
                ai_order: lesson.ai_order,
                mastered_require: lesson.mastered_require,
                topic_uid: lesson.topic_uid,
            }).then((res: { data: Lesson }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewLesson(lesson: ILessonWithTopicUID) {
        return new Promise<Lesson>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson`, {
                uid: lesson.uid,
                name: lesson.name,
                description: lesson.description,
                order: lesson.order,
                ai_order: lesson.ai_order,
                mastered_require: lesson.mastered_require,
                topic_uid: lesson.topic_uid,
            }).then((res: { data: Lesson }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeLesson(lesson_uid: string) {
        return new Promise<Lesson>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/topic-lesson-question/lesson?uid=${lesson_uid}`).then((res: { data: Lesson }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateEditQuestion(question: Question) {
        return new Promise<Question>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/${question.hand_number}`, {
                hand_number: question.hand_number,
                question: question.question,
                hand_history: question.hand_history,
                answer_correct: question.answer_correct,
                answer_wrong: question.answer_wrong,
                explanation_correct: question.explanation_correct,
                explanation_wrong: question.explanation_wrong,
                reward_chip: question.reward_chip,
                reward_ticket: question.reward_ticket,
                new_handed_at: question.new_handed_at,
                lesson_uid: question.lesson_uid,
                blocked_at: question.blocked_at,
            }).then((res: { data: Question }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewQuestion(question: Question) {
        return new Promise<Question>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question`, {
                hand_number: question.hand_number,
                question: question.question,
                hand_history: question.hand_history,
                answer_correct: question.answer_correct,
                answer_wrong: question.answer_wrong,
                explanation_correct: question.explanation_correct,
                explanation_wrong: question.explanation_wrong,
                reward_chip: question.reward_chip,
                reward_ticket: question.reward_ticket,
                new_handed_at: question.new_handed_at,
                lesson_uid: question.lesson_uid,
            }).then((res: { data: Question }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeQuestion(hand_number: number) {
        return new Promise<Question>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question?hand_number=${hand_number}`).then((res: { data: Question }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateEditGlossary(glossary: Omit<Glossary, "created_at" | "updated_at">) {
        return new Promise<Glossary>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary/${glossary.id}`, {
                word: glossary.word,
                definition: glossary.definition,
            }).then((res: { data: Glossary }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewGlossary(glossary: Omit<Glossary, "id" | "created_at" | "updated_at">) {
        return new Promise<Glossary>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary`, {
                word: glossary.word,
                definition: glossary.definition,
            }).then((res: { data: Glossary }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeGlossary(glossary_id: string) {
        return new Promise<Glossary>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary?id=${glossary_id}`).then((res: { data: Glossary }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadQuestionsCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadQuestionsBackupV1CSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/question/upload-from-v1-db-backup`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadGlossariesCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/topic-lesson-question/glossary/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const topicLessonQuestionService = new TopicLessonQuestionService();