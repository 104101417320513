import { ReportUser } from "@prisma/client";
import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";

class ReportService {
  getDailyReportEmails() {
    return new Promise<ReportUser[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/report/emails`).then((res: { data: ReportUser[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  registerNewEmail(data: Omit<ReportUser, 'id'>) {
    return new Promise<ReportUser>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/report/register-user`, data).then((res: { data: ReportUser }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  updateEditUser(data: ReportUser) {
    return new Promise<ReportUser>((resolve, reject) => {
      axios.patch(`${API_BACKEND_ENDPOINT}/report/update-user/${data.id}`, data).then((res: { data: ReportUser }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  deleteUser(id: number) {
    return new Promise<ReportUser>((resolve, reject) => {
      axios.delete(`${API_BACKEND_ENDPOINT}/report/delete-user/${id}`).then((res: { data: ReportUser }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  sendReport(email: string) {
    return new Promise<boolean>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/report/send-report`, {
        email
      }).then((res: { data: boolean }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  sendCustomReport(from: string, to: string, email: string) {
    return new Promise<boolean>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/report/send-customized-report`, {
        from: from,
        to: to,
        email: email,
        include_users: true,
        include_subscriptions: true,
      }).then((res: { data: boolean }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
}

export const reportService = new ReportService();
