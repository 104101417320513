import { useBus } from "react-bus";
import Modal from "react-responsive-modal";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { ChangeEvent, useState } from "react";
import { Lesson } from "@prisma/client";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { set_lessons } from "../../stores/topic-lesson-question";
import lodash from "lodash";
import { toast } from "react-toastify";

export const LessonsPanel = () => {
  const bus = useBus();
  const dispatch = useAppDispatch();
  const { lessons, topics } = useAppSelector((state) => state.topicLessonQuestion);
  const [loading_lessons, set_loading_lessons] = useState<boolean>(false);
  const [search_word, set_search_word] = useState<string>('');
  const [open_new_lesson, set_open_new_lesson] = useState<boolean>(false);
  const [new_lesson, set_new_lesson] = useState<Omit<Lesson, "created_at" | "updated_at">>({
    topic_uid: "",
    uid: "",
    name: "",
    description: "",
    order: 0,
    ai_order: 0,
    mastered_require: 0,
    ai_opened_require: 0,
  });
  const [open_edit_lesson, set_open_edit_lesson] = useState<boolean>(false);
  const [edit_lesson, set_edit_lesson] = useState<Omit<Lesson, "created_at" | "updated_at">>({
    topic_uid: "",
    uid: "",
    name: "",
    description: "",
    order: 0,
    ai_order: 0,
    mastered_require: 0,
    ai_opened_require: 0,
  });
  const update_edit_lesson = () => {
    set_loading_lessons(true);
    topicLessonQuestionService.updateEditLesson({
      uid: edit_lesson.uid,
      name: edit_lesson.name,
      description: edit_lesson.description,
      order: edit_lesson.order,
      ai_order: edit_lesson.ai_order ?? edit_lesson.order,
      mastered_require: edit_lesson.mastered_require,
      topic_uid: edit_lesson.topic_uid,
    }).then((_lesson) => {
      dispatch(
        set_lessons((lessons ?? []).map((_prev_lesson) => _prev_lesson.uid === _lesson.uid ? _lesson : _prev_lesson))
      )
      toast.success(`Lesson (${_lesson.uid}) is updated.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_lessons(false);
    })
  }
  const register_new_lesson = () => {
    set_loading_lessons(true);
    topicLessonQuestionService.registerNewLesson({
      uid: new_lesson.uid,
      name: new_lesson.name,
      description: new_lesson.description,
      order: new_lesson.order,
      ai_order: new_lesson.ai_order ?? new_lesson.order,
      mastered_require: new_lesson.mastered_require,
      topic_uid: new_lesson.topic_uid,
    }).then((_lesson) => {
      dispatch(
        set_lessons([...(lessons ?? []), _lesson])
      )
      toast.success(`Lesson (${_lesson.uid}) is registered.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_lessons(false);
    })
  }
  const delete_lesson = (lesson_uid: string) => {
    const yes = confirm(`Do you want to delete lesson ${lesson_uid}?`);
    if (yes) {
      set_loading_lessons(true);
      topicLessonQuestionService.removeLesson(lesson_uid).then((_removed_lesson) => {
        dispatch(
          set_lessons((lessons ?? []).filter((lesson) => lesson.uid !== _removed_lesson.uid))
        )
        toast.success(`Lesson ${_removed_lesson.uid} is deleted.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_lessons(false);
      });
    }
  }

  const lessons_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_lessons(true);
      topicLessonQuestionService.uploadLessonCSV(file).then((success) => {
        bus.emit('refetch-lessons');
        toast.success(`Lesson csv file is uploaded successfully.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_lessons(false);
      });
    }
  }
  const lessons_backup_v1_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_lessons(true);
      topicLessonQuestionService.uploadLessonBackupV1CSV(file).then((success) => {
        bus.emit('refetch-lessons');
        toast.warn(`Lesson csv file(v1) is uploaded successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_lessons(false);
      });
    }
  }
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center gap-6 px-4 py-2 text-white mt-4">
        <div>
          <input className="text-black" type="text" placeholder="Search Word" onChange={lodash.debounce((e) => {
            set_search_word(e.target.value);
          }, 500)} />
        </div>
        <div className="flex items-center gap-6">
          <button className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1" onClick={() => set_open_new_lesson(true)}>Register</button>
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-csv-file-upload"
            title="Upload CSV Data from google sheet"
          >
            Upload CSV
          </label>
          <input id="topics-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={lessons_csv_upload_handler} />
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-backup-v1-csv-file-upload"
            title="Upload CSV Data from v1 db backup"
          >
            Upload CSV V1
          </label>
          <input id="topics-backup-v1-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={lessons_backup_v1_csv_upload_handler} />
        </div>
      </div>
      <div className="w-full flex items-start gap-4 text-white border-b py-4 px-4">
        <div className="w-10 grow-0 shrink-0">
          Topic
        </div>
        <div className="w-16 grow-0 shrink-0">
          UID
        </div>
        <div className="w-40 grow-0 shrink-0">
          Name
        </div>
        <div className="grow shrink max-h-[64px] overflow-auto thin-scrollbar">
          Description
        </div>
        <div className="w-10 grow-0 shrink-0">
          Order
        </div>
        <div className="w-16 grow-0 shrink-0">
          Order(AI)
        </div>
        <div className="w-10 grow-0 shrink-0">
        </div>
        <div className="w-60 grow-0 shrink-0">
          Last Updated Time
        </div>
        <div className="w-32 flex items-center gap-6 grow-0 shrink-0">
          Actions
        </div>
      </div>
      {(lessons ?? []).filter((lesson) => lesson.name.toLowerCase().includes(search_word.toLowerCase()) || lesson.description.toLowerCase().includes(search_word.toLowerCase()) || lesson.uid.toLowerCase().includes(search_word.toLowerCase())).map((lesson, id) => (
        <div className="w-full flex items-start gap-4 text-white border-b py-4 px-4" key={id}>
          <div className="w-10 grow-0 shrink-0">
            {lesson.topic_uid}
          </div>
          <div className="w-16 grow-0 shrink-0">
            {lesson.uid}
          </div>
          <div className="w-40 grow-0 shrink-0">
            {lesson.name}
          </div>
          <div className="grow shrink max-h-[64px] overflow-auto thin-scrollbar">
            {lesson.description}
          </div>
          <div className="w-10 grow-0 shrink-0">
            {lesson.order}
          </div>
          <div className="w-16 grow-0 shrink-0">
            {lesson.ai_order ?? lesson.order}
          </div>
          <div className="w-10 grow-0 shrink-0">
            {lesson.mastered_require}
          </div>
          <div className="w-60 grow-0 shrink-0">
            {lesson.updated_at.toString()} (UTC)
          </div>
          <div className="w-32 flex items-center gap-6 grow-0 shrink-0">
            <button
              onClick={() => {
                set_edit_lesson({
                  uid: lesson.uid,
                  name: lesson.name,
                  mastered_require: lesson.mastered_require,
                  ai_opened_require: lesson.ai_opened_require,
                  description: lesson.description,
                  order: lesson.order,
                  ai_order: lesson.ai_order,
                  topic_uid: lesson.topic_uid,
                });
                set_open_edit_lesson(true);
              }}
            >Edit</button>
            <button
              onClick={() => {
                delete_lesson(lesson.uid);
              }}
            >Delete</button>
          </div>
        </div>
      ))}
      <Modal open={open_edit_lesson} onClose={() => set_open_edit_lesson(false)}>
        <h2>Update Lesson {edit_lesson.uid}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Topic ID" title="Required topic id of lesson">Lesson Topic ID(*)</label>
          <select className="grow shrink border p-2" value={edit_lesson.topic_uid} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))}>
            {(topics ?? []).map((topic, id) => <option key={id} value={topic.uid}>{topic.name}</option>)}
          </select>
          {/* <input className="grow shrink border p-2" type="text" id="Edit Lesson Topic ID" value={edit_lesson.topic_uid} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))} /> */}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Name" title="Required name of lesson">Lesson Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="Edit Lesson Name" value={edit_lesson.name} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, name: e.target.value }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Description" title="Required description of lesson">Lesson Description(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="Edit Lesson Description" value={edit_lesson.description} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Order" title="Required id of lesson">Lesson Order(*)</label>
          <input className="grow shrink border p-2" type="number" id="Edit Lesson Order" value={edit_lesson.order} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, order: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Order" title="Required id of lesson">Lesson Order(AI)</label>
          <input className="grow shrink border p-2" type="number" id="Edit Lesson Order" value={edit_lesson.ai_order ?? edit_lesson.order} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, ai_order: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Lesson Mastered Require" title="Required required chips of lesson">Mastered Require(*)</label>
          <input className="grow shrink border p-2" type="text" id="Edit Lesson Mastered Require" value={edit_lesson.mastered_require} onChange={(e) => set_edit_lesson((_prev) => ({ ..._prev, mastered_require: parseInt(e.target.value) }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_lesson(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_lessons} onClick={update_edit_lesson}>{loading_lessons ? 'Updating' : 'Update'}</button>
        </div>
      </Modal>
      <Modal open={open_new_lesson} onClose={() => set_open_new_lesson(false)}>
        <h2>Register New Lesson</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Topic ID" title="Required topic id of lesson">Lesson Topic ID(*)</label>
          <select className="grow shrink border p-2" value={new_lesson.topic_uid} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))}>
            <option value="un-selected">Select Your Topic</option>
            {(topics ?? []).map((topic, id) => <option key={id} value={topic.uid}>{topic.name}</option>)}
          </select>
          {/* <input className="grow shrink border p-2" type="text" id="Edit Lesson Topic ID" value={new_lesson.topic_uid} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))} /> */}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson UID" title="Required id of lesson">Lesson UID(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Lesson UID" value={new_lesson.uid} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, uid: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Name" title="Required name of lesson">Lesson Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Lesson Name" value={new_lesson.name} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, name: e.target.value }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Description" title="Required description of lesson">Lesson Description(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="New Lesson Description" value={new_lesson.description} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Order" title="Required id of lesson">Lesson Order(*)</label>
          <input className="grow shrink border p-2" type="number" id="New Lesson Order" value={new_lesson.order} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, order: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Order" title="Required id of lesson">Lesson Order(AI)</label>
          <input className="grow shrink border p-2" type="number" id="New Lesson Order" value={new_lesson.ai_order ?? new_lesson.order} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, ai_order: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Lesson Mastered Require" title="Required required chips of lesson">Mastered Require(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Lesson Mastered Require" value={new_lesson.mastered_require} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, mastered_require: parseInt(e.target.value) }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_lesson(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_lessons} onClick={register_new_lesson}>{loading_lessons ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
    </div>
  )
}