import axios, { AxiosError } from "axios";

const V1EndPoint = process.env.NX_V1_ENDPOINT

export interface V1Earning {
  id: number; // 23433
  challenge: number;
  chips: number;
  correct: number; // 1 correct, 0 wrong
  createdAt: string; // "2024-02-23T09:03:37.000Z"
  handNumber: number;
  questionID: number; // 572718
  tickets: number; // 9
  userID: number; // 158
}

class V1Service {
  getV1Token(email: string, password: string) {
    return new Promise<{ token: string }>((resolve, reject) => {
      axios.post(`${V1EndPoint}/getUserByEmail`, { email, password }).then((res: { data: { token: string } }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }

  getV1Data(id: string, token: string) {
    return new Promise<{
      referEmail: string;
      earnings: V1Earning[],
      watchedLibraries: string[],
      couponCode: string,
    }>((resolve, reject) => {
      axios.post(`${V1EndPoint}/getV1DataForV2`, {
        userID: id
      }, {
        headers: { Authorization: `${token}` }
      }).then((res: {
        data: {
          referEmail: string;
          earnings: V1Earning[],
          watchedLibraries: string[],
          couponCode: string,
        }
      }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
}

export const v1Service = new V1Service();
