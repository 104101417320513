import moment from "moment";
import { useState } from "react";
import { historyService } from "../services/history.service";
import lodash from "lodash";
import { IcRight } from "@clc-v2/icons";
import classNames from "classnames";

const format = "YYYY-MM-DD";

const convert_sort = (sort: 'asc' | 'desc' | null) => {
  if (sort === 'asc') {
    return 'desc'
  } else if (sort === 'desc') {
    return null
  } else {
    return 'asc'
  }
}

export const CustomerEarning = () => {
  const [from, set_from] = useState<string>(moment().format(format));
  const [to, set_to] = useState<string>(moment().format(format));
  const [email, set_email] = useState<string>('');
  const [customer_earning, set_customer_earning] = useState<Array<{
    email: string;
    username: string;
    type: string;
    correct: number;
    total: number;
    earning_chips: number;
    earning_tickets: number;
  }>>([]);
  const [sort_email, set_sort_email] = useState<'asc' | 'desc' | null>(null);
  const [sort_username, set_sort_username] = useState<'asc' | 'desc' | null>(null);
  const [sort_type, set_sort_type] = useState<'asc' | 'desc' | null>(null);
  const [sort_correct, set_sort_correct] = useState<'asc' | 'desc' | null>(null);
  const [sort_total, set_sort_total] = useState<'asc' | 'desc' | null>(null);
  const [sort_chips, set_sort_chips] = useState<'asc' | 'desc' | null>(null);
  const [sort_tickets, set_sort_tickets] = useState<'asc' | 'desc' | null>(null);
  const filter_handler = () => {
    historyService.getDailyHistoryRank(from, to, email).then((_daily_history) => {
      const grouped_earning = lodash.groupBy(_daily_history, (el) => el.user.email);
      const _customer_earning = Object.keys(grouped_earning).map((user_email) => ({
        email: user_email,
        username: grouped_earning[user_email][0].user.user_name,
        type: grouped_earning[user_email][0].user.type,
        correct: grouped_earning[user_email].reduce((a, b) => a + b.correct, 0),
        total: grouped_earning[user_email].reduce((a, b) => a + b.total, 0),
        earning_chips: grouped_earning[user_email].reduce((a, b) => a + b.chips, 0),
        earning_tickets: grouped_earning[user_email].reduce((a, b) => a + b.earned_tickets, 0),
      }));
      set_customer_earning(_customer_earning);
    }).catch((err_msg: string) => {
      alert(err_msg);
    })
  }
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <input type="date" value={from} onChange={(e) => set_from(moment(e.target.value).format(format))} />
        <input type="date" value={to} onChange={(e) => set_to(moment(e.target.value).format(format))} />
        <input type="email" value={email} onChange={(e) => set_email(e.target.value)} placeholder="User email" />
        <button className="text-white" onClick={filter_handler}>Filter</button>
      </div>
      <div className="w-full flex items-start gap-4 py-2 border-b border-gray-500">
        <div className="grow shrink text-white flex justify-between items-center">
          <span>Email</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_email((_prev) => convert_sort(_prev))
            set_sort_username(null)
            set_sort_type(null)
            set_sort_correct(null)
            set_sort_total(null)
            set_sort_chips(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_email === 'asc',
                "block": sort_email === 'desc' || sort_email === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_email === 'asc' || sort_email === null,
                "hidden": sort_email === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>User Name</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_username((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_type(null)
            set_sort_correct(null)
            set_sort_total(null)
            set_sort_chips(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_username === 'asc',
                "block": sort_username === 'desc' || sort_username === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_username === 'asc' || sort_username === null,
                "hidden": sort_username === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>Type</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_type((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_username(null)
            set_sort_correct(null)
            set_sort_total(null)
            set_sort_chips(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_type === 'asc',
                "block": sort_type === 'desc' || sort_type === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_type === 'asc' || sort_type === null,
                "hidden": sort_type === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>Correct</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_correct((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_username(null)
            set_sort_type(null)
            set_sort_total(null)
            set_sort_chips(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_correct === 'asc',
                "block": sort_correct === 'desc' || sort_correct === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_correct === 'asc' || sort_correct === null,
                "hidden": sort_correct === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>Total</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_total((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_username(null)
            set_sort_type(null)
            set_sort_correct(null)
            set_sort_chips(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_total === 'asc',
                "block": sort_total === 'desc' || sort_total === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_total === 'asc' || sort_total === null,
                "hidden": sort_total === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>Earning Chips</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_chips((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_username(null)
            set_sort_type(null)
            set_sort_correct(null)
            set_sort_total(null)
            set_sort_tickets(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_chips === 'asc',
                "block": sort_chips === 'desc' || sort_chips === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_chips === 'asc' || sort_chips === null,
                "hidden": sort_chips === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
        <div className="w-36 grow-0 shrink-0 text-white flex justify-between items-center">
          <span>Earning Tickets</span>
          <div className="flex flex-col justify-center items-center cursor-pointer w-6 h-6 border" onClick={() => {
            set_sort_tickets((_prev) => convert_sort(_prev))
            set_sort_email(null)
            set_sort_username(null)
            set_sort_type(null)
            set_sort_correct(null)
            set_sort_total(null)
            set_sort_chips(null)
          }}>
            <div className={classNames(
              "transform -rotate-90",
              {
                "hidden": sort_tickets === 'asc',
                "block": sort_tickets === 'desc' || sort_tickets === null,
              }
            )}><IcRight /></div>
            <div className={classNames(
              "transform rotate-90",
              {
                "block": sort_tickets === 'asc' || sort_tickets === null,
                "hidden": sort_tickets === 'desc',
              }
            )}><IcRight /></div>
          </div>
        </div>
      </div>
      {customer_earning.sort((a, b) => {
        if (sort_email === 'asc') {
          return a.email < b.email ? 1 : -1
        } else if (sort_email === 'desc') {
          return a.email > b.email ? 1 : -1
        }
        if (sort_username === 'asc') {
          return a.username < b.username ? 1 : -1
        } else if (sort_username === 'desc') {
          return a.username > b.username ? 1 : -1
        }
        if (sort_type === 'asc') {
          return a.type < b.type ? 1 : -1
        } else if (sort_type === 'desc') {
          return a.type > b.type ? 1 : -1
        }
        if (sort_correct === 'asc') {
          return a.correct < b.correct ? 1 : -1
        } else if (sort_correct === 'desc') {
          return a.correct > b.correct ? 1 : -1
        }
        if (sort_total === 'asc') {
          return a.total < b.total ? 1 : -1
        } else if (sort_total === 'desc') {
          return a.total > b.total ? 1 : -1
        }
        if (sort_chips === 'asc') {
          return a.earning_chips < b.earning_chips ? 1 : -1
        } else if (sort_chips === 'desc') {
          return a.earning_chips > b.earning_chips ? 1 : -1
        }
        if (sort_tickets === 'asc') {
          return a.earning_tickets < b.earning_tickets ? 1 : -1
        } else if (sort_tickets === 'desc') {
          return a.earning_tickets > b.earning_tickets ? 1 : -1
        }
        return 1
      }).map((earning, id) => (
        <div className="w-full flex items-start gap-4">
          <p className="grow shrink text-white">{earning.email}</p>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.username}
          </div>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.type}
          </div>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.correct}
          </div>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.total}
          </div>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.earning_chips}
          </div>
          <div className="w-36 grow-0 shrink-0 text-white">
            {earning.earning_tickets}
          </div>
        </div>
      ))}
    </div>
  )
}