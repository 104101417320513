import { IcAdd, IcUpload } from "@clc-v2/icons"
import { dataService } from "../services/data.service"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { CLCAvatars, LatestArticles, PrizeSliceText } from "@prisma/client";
import { Button, CardArticle } from "@clc-v2/uis";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import { imageService } from "../services/image.service";
import { JsonView } from "react-json-view-lite";
import 'react-json-view-lite/dist/index.css';
import { AVATARS, splitFileName, UTMConfigs } from "@clc-v2/utilities";
import { NX_DOMAIN } from "../config";

export const Data = () => {
	const [prize_texts, set_prize_texts] = useState<PrizeSliceText[]>([]);
	const [open_new_prize_text, set_open_new_prize_text] = useState<boolean>(false);
	const [loading_prize_text, set_loading_prize_text] = useState<boolean>(false);
	const [new_prize_text, set_new_prize_text] = useState<Omit<PrizeSliceText, 'id'>>({
		text: '',
		order: 0
	});
	const [open_edit_prize_text, set_open_edit_prize_text] = useState<boolean>(false);
	const [edit_prize_text, set_edit_prize_text] = useState<PrizeSliceText>({
		id: '',
		text: '',
		order: 0
	});
	const [loading_articles, set_loading_articles] = useState<boolean>(false);
	const [latest_articles, set_latest_articles] = useState<LatestArticles[]>([]);
	const [open_new_article, set_open_new_article] = useState<boolean>(false);
	const [new_article, set_new_article] = useState<Omit<LatestArticles, "id">>({
		title: '',
		date: '',
		image: '',
		link: '',
	});
	const [open_edit_article, set_open_edit_article] = useState<boolean>(false);
	const [edit_article, set_edit_article] = useState<LatestArticles>({
		id: '',
		title: '',
		date: '',
		image: '',
		link: '',
	});
	const [enabled_verification_email, set_enabled_verification_email] = useState<boolean>(false);
	const [count_daily_questions, set_count_daily_questions] = useState<number>(0);
	const [loading_clc_avatars, set_loading_clc_avatars] = useState<boolean>(false);
	const [clc_avatars, set_clc_avatars] = useState<CLCAvatars[]>([]);
	const [open_add_clc_avatar, set_open_add_clc_avatar] = useState<boolean>(false);
	const [new_clc_avatar, set_new_clc_avatar] = useState<Omit<CLCAvatars, 'id'>>({
		name: '',
		path: '',
		thumbnail_path: ''
	});
	const [new_clc_avatar_file, set_new_clc_avatar_file] = useState<File>();
	const [new_clc_avatar_file_64_64, set_new_clc_avatar_file_64_64] = useState<File>();
	const [open_gcs_modal, set_open_gcs_modal] = useState<boolean>(false);
	const [gcs_json, set_gcs_json] = useState<Object>();
	const [updating_cors, set_updating_cors] = useState<boolean>(false);
	const [cors_domains, set_cors_domains] = useState<string>('');
	const [loading_count_intro_video, set_loading_count_intro_video] = useState<boolean>(false);
	const [count_intro_video_from, set_count_intro_video_from] = useState<string>('')
	const [count_intro_video_to, set_count_intro_video_to] = useState<string>('')
	const [count_intro_video, set_count_intro_video] = useState<Array<{ count: number; month: string; }>>([]);
	const [loading_count_discord_tutorial_video, set_loading_count_discord_tutorial_video] = useState<boolean>(false);
	const [count_discord_tutorial_video_from, set_count_discord_tutorial_video_from] = useState<string>('')
	const [count_discord_tutorial_video_to, set_count_discord_tutorial_video_to] = useState<string>('')
	const [count_discord_tutorial_video, set_count_discord_tutorial_video] = useState<Array<{ count: number; month: string; }>>([]);
	const [loading_utm_configs, set_loading_utm_configs] = useState<boolean>(false);
	const [utm_configs, set_utm_configs] = useState<UTMConfigs>({});
	const get_prize_texts = () => {
		dataService.getPrizeTexts().then((_prize_texts) => {
			set_prize_texts(_prize_texts);
		}).catch((err_msg: string) => {
			console.error(err_msg);
		});
	}
	const register_new_prize_text = () => {
		set_loading_prize_text(true);
		dataService.addPrizeText(new_prize_text).then((_prize_text) => {
			set_prize_texts((_prev) => [..._prev, _prize_text]);
			set_open_new_prize_text(false);
			toast.success(`Prize text (${_prize_text.id}) is registered`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).catch((err_msg: string) => {
			toast.warn(err_msg, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).finally(() => {
			set_loading_prize_text(false)
		});
	}
	const update_edit_prize_text = () => {
		set_loading_prize_text(true);
		dataService.updatePrizeText(edit_prize_text).then((_prize_text) => {
			set_prize_texts((_prev) => _prev.map((prize_text) => prize_text.id === _prize_text.id ? _prize_text : prize_text));
			set_open_edit_prize_text(false);
			toast.success(`Prize text (${_prize_text.id}) is updated`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).catch((err_msg: string) => {
			toast.warn(err_msg, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).finally(() => {
			set_loading_prize_text(false);
		});
	}
	const delete_prize_text = (id: string) => {
		const yes = confirm(`Do you want to delete prize text ${id}?`);
		if (yes) {
			dataService.removePrizeText(id).then((_removed_prize_text) => {
				set_prize_texts((_prev) => _prev.filter((prize_text) => prize_text.id !== _removed_prize_text.id));
				toast.success(`Prize text (${_removed_prize_text.id}) is deleted`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			}).catch((err_msg: string) => {
				toast.warn(err_msg, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			});
		}
	}
	const prize_texts_csv_file_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			const file: File = e.target.files[0];
			dataService.uploadPrizeTextsCSV(file).then(() => {
				get_prize_texts();
				toast.success(`Prize text csv file is uploaded successfully`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			}).catch((err_msg: string) => {
				toast.warn(err_msg, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			});
		}
	}
	const get_latest_articles = () => {
		dataService.getLatestArticles().then((_latest_articles) => {
			set_latest_articles(_latest_articles);
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const register_new_article = () => {
		set_loading_articles(true);
		dataService.addLatestArticles(new_article).then((_latest_article) => {
			set_latest_articles((_prev) => [..._prev, _latest_article]);
			set_open_new_article(false);
			toast.success(`New article (${_latest_article.id}) is registered`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).catch((err_msg: string) => {
			toast.warn(err_msg, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).finally(() => {
			set_loading_articles(false);
		});
	}
	const update_edit_article = () => {
		set_loading_articles(true);
		dataService.updateLatestArticle(edit_article).then((_latest_article) => {
			set_latest_articles((_prev) => _prev.map((latest_article) => latest_article.id === _latest_article.id ? _latest_article : latest_article));
			set_open_edit_article(false);
			toast.success(`Article (${_latest_article.id}) is updated`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).catch((err_msg: string) => {
			toast.warn(err_msg, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
		}).finally(() => {
			set_loading_articles(false);
		});
	}
	const delete_latest_article = (id: string) => {
		const yes = confirm(`Do you want to delete article ${id}?`);
		if (yes) {
			dataService.deleteLatestArticle(id).then((_removed_article) => {
				set_latest_articles((_prev) => _prev.filter((article) => article.id !== _removed_article.id))
				toast.success(`Article (${_removed_article.id}) is deleted`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			}).catch((err_msg: string) => {
				toast.warn(err_msg, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			});
		}
	}
	const get_enabled_verification_email = () => {
		dataService.getEnabledVerificationEmail().then((enabled) => {
			set_enabled_verification_email(enabled);
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const update_verification_email = (enable: boolean) => {
		dataService.updateEnabledVerificationEmail(enable).then(() => {
			set_enabled_verification_email(enable);
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const get_count_daily_questions = () => {
		dataService.getCountDailyQuestions().then((_count_daily_questions) => {
			set_count_daily_questions(_count_daily_questions);
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const update_count_daily_questions = () => {
		dataService.updateCountDailyQuestions(count_daily_questions).then((_count_daily_questions) => {
			set_count_daily_questions(_count_daily_questions);
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const get_clc_avatars = () => {
		set_loading_clc_avatars(true);
		dataService.getCLCAvatars().then((_clc_avatars) => {
			set_clc_avatars(_clc_avatars);
		}).catch((err_msg: string) => {
			alert(err_msg);
		}).finally(() => {
			set_loading_clc_avatars(false);
		});
	}
	const add_clc_avatar = () => {
		if (new_clc_avatar && new_clc_avatar.name) {
			if (new_clc_avatar_file && new_clc_avatar_file_64_64) {
				set_loading_clc_avatars(true);
				const timestamp = new Date().getTime();
				const { name, extension } = splitFileName(new_clc_avatar_file.name.trim());
				const file_name = `${name}-${timestamp}` + (extension ? `.${extension}` : '');
				const file_name_64 = `${name}-${timestamp}_64_64` + (extension ? `.${extension}` : '');
				imageService.getSignedURL('avatars', file_name, new_clc_avatar_file.type).then(({ signed_url: _signed_url, path }) => {
					imageService.uploadFileToGCS(_signed_url, new_clc_avatar_file).then(() => {
						imageService.getSignedURL('avatars', file_name_64, new_clc_avatar_file_64_64.type).then(({ signed_url: _signed_url, path: thumbnail_path }) => {
							imageService.uploadFileToGCS(_signed_url, new_clc_avatar_file_64_64).then(() => {
								set_loading_clc_avatars(false);
								dataService.addCLCAvatar({
									name: new_clc_avatar.name,
									path: path,
									thumbnail_path: thumbnail_path,
								}).then((_new_avatar) => {
									set_clc_avatars((_prev) => [..._prev, _new_avatar]);
								}).catch((err_msg: string) => {
									alert(err_msg);
								});
							}).catch((err) => {
								set_loading_clc_avatars(false);
								alert(err);
							})
						});
					}).catch((err) => {
						set_loading_clc_avatars(false);
						alert(err)
					});
				}).catch((err_msg: string) => {
					set_loading_clc_avatars(false);
					alert(err_msg);
				});
			} else {
				alert('Select avatar image');
			}
		} else {
			alert('Name is required');
		}
	}
	const delete_clc_avatar = (id: string) => {
		dataService.deleteCLCAvatar(id).then(() => {
			set_clc_avatars((_prev) => _prev.filter((clc_avatar) => clc_avatar.id !== id))
		}).catch((err_msg: string) => {
			alert(err_msg);
		});
	}
	const update_cors = () => {
		set_updating_cors(true);
		imageService.updateGCSCors(cors_domains.split(',').map((domain) => domain.trim())).then((_json) => {
			set_gcs_json(_json);
		}).catch((err_msg: string) => {
			alert(err_msg)
		}).finally(() => {
			set_updating_cors(false);
		})
	}
	const get_count_intro_video = () => {
		set_loading_count_intro_video(true);
		dataService.getCountIntroVideo(count_intro_video_from, count_intro_video_to).then((_count_intro_video) => {
			set_count_intro_video(_count_intro_video);
		}).catch((err_msg: string) => {
			alert(err_msg)
		}).finally(() => {
			set_loading_count_intro_video(false);
		})
	}
	const get_count_discord_tutorial_video = () => {
		set_loading_count_discord_tutorial_video(true);
		dataService.getCountDiscordTutorialVideo(count_discord_tutorial_video_from, count_discord_tutorial_video_to).then((_count_discord_tutorial_video) => {
			set_count_discord_tutorial_video(_count_discord_tutorial_video);
		}).catch((err_msg: string) => {
			alert(err_msg)
		}).finally(() => {
			set_loading_count_intro_video(false);
		})
	}
	const get_utm_configs = () => {
		set_loading_utm_configs(true);
		dataService.getUtmConfigs().then((_utm_configs) => {
			set_utm_configs(_utm_configs);
		}).catch((err_msg: string) => {
			console.error(err_msg);
		}).finally(() => {
			set_loading_utm_configs(false);
		});
	}
	const update_utm_configs = () => {
		dataService.updateUtmConfigs(utm_configs).then(() => {

		}).catch((err_msg: string) => {

		}).finally(() => {

		});
	}
	useEffect(() => {
		get_prize_texts();
		get_latest_articles();
		get_enabled_verification_email();
		get_count_daily_questions();
		get_clc_avatars();
		get_count_intro_video();
		get_utm_configs();
	}, []);
	useEffect(() => {
		if (new_clc_avatar_file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					canvas.width = 64;
					canvas.height = 64;
					ctx?.drawImage(img, 0, 0, 64, 64);
					canvas.toBlob((blob) => {
						if (blob) {
							const resizedImageFile = new File([blob], new_clc_avatar_file.name, { type: new_clc_avatar_file.type });
							set_new_clc_avatar_file_64_64(resizedImageFile);
						}
					}, new_clc_avatar_file.type);
				};
				img.src = e.target?.result as string;
				set_new_clc_avatar((_prev) => ({ ..._prev, path: e.target?.result as string }));
			};
			reader.readAsDataURL(new_clc_avatar_file);
		} else {
			set_new_clc_avatar_file_64_64(undefined);
			set_new_clc_avatar((_prev) => ({ ..._prev, path: '' }));
		}
	}, [new_clc_avatar_file]);
	useEffect(() => {
		if (new_clc_avatar_file_64_64) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					canvas.width = 64;
					canvas.height = 64;
					ctx?.clearRect(0, 0, canvas.width, canvas.height);
					ctx?.drawImage(img, 0, 0, 64, 64);
					const resizedImageUrl = canvas.toDataURL(new_clc_avatar_file_64_64.type);
					set_new_clc_avatar((_prev) => ({ ..._prev, thumbnail_path: resizedImageUrl }));
				};
				img.src = e.target?.result as string;
			}
			reader.readAsDataURL(new_clc_avatar_file_64_64);
		} else {
			set_new_clc_avatar((_prev) => ({ ..._prev, thumbnail_path: '' }));
		}
	}, [new_clc_avatar_file_64_64]);
	useEffect(() => {
		if (open_gcs_modal) {
			imageService.viewGCSStatus().then((_json) => {
				set_gcs_json(_json);
			}).catch((err_msg: string) => {
				alert(err_msg);
			})
		}
	}, [open_gcs_modal]);
	return (
		<>
			<div className="w-full flex items-center gap-4">
				<span className="text-white">Prize Texts</span>
				<div title="Add New or Update Library" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" onClick={() => set_open_new_prize_text(true)}>
					<IcAdd />
				</div>
				<label title="Upload CSV of Prize Texts" className="rounded-full h-5 flex justify-start items-center cursor-pointer " htmlFor="prize-texts-csv-file-upload">
					<IcUpload />
				</label>
				<input id="prize-texts-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={prize_texts_csv_file_upload_handler} />
			</div>
			<div className="w-full mt-4">
				{prize_texts.sort((a, b) => a.order > b.order ? 1 : -1).map(({ id, text, order }) => (
					<div className="flex gap-4 text-white border px-4 py-1" key={id}>
						<span className="w-10 shrink-0 grow-0">{order}</span>
						<p className="shrink grow">{text}</p>
						<div className="flex items-center gap-4">
							<button className="text-white" onClick={() => {
								set_edit_prize_text({ id, text, order });
								set_open_edit_prize_text(true);
							}}>Edit</button>
							<button className="text-white" onClick={() => delete_prize_text(id)}>Delete</button>
						</div>
					</div>
				))}
			</div>
			<div className="w-full mt-6">
				<Button className="text-white px-4 py-2" type="secondary" onClick={() => set_open_new_article(true)}>
					Add Latest Article
				</Button>
				<div className="w-full grid grid-cols-3 gap-4 mt-4">
					{latest_articles.map((latest_article) => (
						<div className="relative" key={latest_article.id}>
							<CardArticle
								bannerImages={[latest_article.image]}
								date={latest_article.date}
								title={latest_article.title}
								readNowHandler={() => new Promise((resolve, reject) => {
									window.open(latest_article.link, '_blank');
									resolve(true);
								})}
							/>
							<div className="absolute right-20 top-4 z-50 bg-white cursor-pointer" onClick={() => {
								set_edit_article(latest_article);
								set_open_edit_article(true);
							}}>
								Edit
							</div>
							<div className="absolute right-4 top-4 z-50 bg-white cursor-pointer" onClick={() => delete_latest_article(latest_article.id)}>
								Delete
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="w-full mt-4 flex items-center gap-2">
				<label className="text-white">Send Verification Email</label>
				<Button className="text-white group" type={enabled_verification_email ? "primary" : "secondary"} onClick={() => update_verification_email(!enabled_verification_email)}>
					<span className="group-hover:hidden">{enabled_verification_email ? 'Enabled' : 'Disabled'}</span>
					<span className="hidden group-hover:block">{enabled_verification_email ? 'Disable?' : 'Enable?'}</span>
				</Button>
			</div>
			<div className="w-full mt-4 flex items-center gap-4">
				<label className="text-white" htmlFor="count-of-daily-questions">Count of Daily Questions</label>
				<input id="count-of-daily-questions" type="number" value={count_daily_questions} onChange={(e) => set_count_daily_questions(parseInt(e.target.value))} />
				<Button className="text-white px-4 py-2" type="secondary" onClick={update_count_daily_questions}>
					Update
				</Button>
			</div>
			<div className="w-full mt-4 flex items-start gap-4">
				<label className="text-white">Count of Watching Intro Video</label>
				<div className="flex flex-col gap-2">
					<div className="flex items-center gap-4">
						<input type="month" value={count_intro_video_from} onChange={(e) => set_count_intro_video_from(e.target.value)} />
						<input type="month" value={count_intro_video_to} onChange={(e) => set_count_intro_video_to(e.target.value)} />
						<Button className="text-white px-4 py-2" type="secondary" onClick={get_count_intro_video}>
							Filter
						</Button>
					</div>
					{count_intro_video.map(({ month, count }) => (
						<div className="flex items-center gap-4 text-white" key={month}>
							<span>{month}</span>
							<span>{count}</span>
						</div>
					))}
				</div>
			</div>
			<div className="w-full mt-4 flex items-start gap-4">
				<label className="text-white">Count of Watching Discord Tutorial Video</label>
				<div className="flex flex-col gap-2">
					<div className="flex items-center gap-4">
						<input type="month" value={count_discord_tutorial_video_from} onChange={(e) => set_count_discord_tutorial_video_from(e.target.value)} />
						<input type="month" value={count_discord_tutorial_video_to} onChange={(e) => set_count_discord_tutorial_video_to(e.target.value)} />
						<Button className="text-white px-4 py-2" type="secondary" onClick={get_count_discord_tutorial_video}>
							Filter
						</Button>
					</div>
					{count_discord_tutorial_video.map(({ month, count }) => (
						<div className="flex items-center gap-4 text-white" key={month}>
							<span>{month}</span>
							<span>{count}</span>
						</div>
					))}
				</div>
			</div>
			<div className="w-full mt-4">
				<div className="flex items-center gap-4">
					<p className="text-white">CLC Avatars</p>
					<Button className="text-white px-4 py-2" type="secondary" onClick={() => set_open_add_clc_avatar(true)}>
						Add
					</Button>
					<Button className="text-white px-4 py-2" type="secondary" onClick={() => set_open_gcs_modal(true)}>
						View GCS
					</Button>
				</div>
				<div className="w-full mt-4 flex items-center gap-4 flex-wrap">
					{AVATARS(NX_DOMAIN).map((avatar, id) => (
						<div className="text-white flex flex-col items-center " key={id}>
							<img src={avatar} alt={avatar} />
						</div>
					))}
					{clc_avatars.map((clc_avatar, id) => (
						<div className="text-white flex flex-col items-center " key={id}>
							<img src={clc_avatar.thumbnail_path} alt={clc_avatar.name ?? clc_avatar.path} />
							<span className="line-clamp-2">{clc_avatar.name}</span>
							<span className="cursor-pointer" onClick={() => delete_clc_avatar(clc_avatar.id)}>Delete</span>
						</div>
					))}
				</div>
			</div>
			<div className="w-full mt-4">
				<div className="flex items-start gap-4">
					<div className="flex flex-col gap-2">
						<p className="text-white">UTM Configurations</p>
						<Button className="text-white px-4 py-2" type="secondary" onClick={() => update_utm_configs()}>
							Update
						</Button>
					</div>
					<div className="flex gap-2 flex-wrap">
						<div className="flex flex-col gap-2">
							<label className="text-white" htmlFor="utm-source">UTM Source</label>
							<input id="utm-source" type="text" className="px-4 py-2" value={utm_configs.utm_source} onChange={(e) => set_utm_configs((_prev) => ({ ..._prev, utm_source: e.target.value }))} />
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-white" htmlFor="utm-medium">UTM Medium</label>
							<input id="utm-medium" type="text" className="px-4 py-2" value={utm_configs.utm_medium} onChange={(e) => set_utm_configs((_prev) => ({ ..._prev, utm_medium: e.target.value }))} />
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-white" htmlFor="utm-campaign">UTM Campaign</label>
							<input id="utm-campaign" type="text" className="px-4 py-2" value={utm_configs.utm_campaign} onChange={(e) => set_utm_configs((_prev) => ({ ..._prev, utm_campaign: e.target.value }))} />
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-white" htmlFor="utm-content">UTM Content</label>
							<input id="utm-content" type="text" className="px-4 py-2" value={utm_configs.utm_content} onChange={(e) => set_utm_configs((_prev) => ({ ..._prev, utm_content: e.target.value }))} />
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-white" htmlFor="utm-term">UTM Term</label>
							<input id="utm-term" type="text" className="px-4 py-2" value={utm_configs.utm_term} onChange={(e) => set_utm_configs((_prev) => ({ ..._prev, utm_term: e.target.value }))} />
						</div>
					</div>
				</div>
			</div>
			<Modal open={open_new_prize_text} onClose={() => set_open_new_prize_text(false)} center>
				<h2>Add New Prize Text</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Prize Text" title="Required text of new prize">Text(*)</label>
					<input className="grow shrink border p-2" type="text" id="New Prize Text" value={new_prize_text.text} onChange={(e) => set_new_prize_text((_prev) => ({ ..._prev, text: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Prize Order" title="Required order of new prize">Order(*)</label>
					<input className="grow shrink border p-2" type="number" id="New Prize Order" value={new_prize_text.order} onChange={(e) => set_new_prize_text((_prev) => ({ ..._prev, order: parseInt(e.target.value) }))} />
				</div>
				<div className="flex justify-between items-center mt-2">
					<button className="px-3 py-1 border text-black" onClick={() => set_open_new_prize_text(false)}>Close</button>
					<button className="px-3 py-1 bg-green text-white" disabled={loading_prize_text} onClick={register_new_prize_text}>{loading_prize_text ? 'Registering' : 'Register'}</button>
				</div>
			</Modal>
			<Modal open={open_edit_prize_text} onClose={() => set_open_edit_prize_text(false)} center>
				<h2>Update Prize Text {edit_prize_text.id}</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Prize Text" title="Required text of new prize">Text(*)</label>
					<input className="grow shrink border p-2" type="text" id="Edit Prize Text" value={edit_prize_text.text} onChange={(e) => set_edit_prize_text((_prev) => ({ ..._prev, text: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Prize Order" title="Required order of new prize">Order(*)</label>
					<input className="grow shrink border p-2" type="number" id="Edit Prize Order" value={edit_prize_text.order} onChange={(e) => set_edit_prize_text((_prev) => ({ ..._prev, order: parseInt(e.target.value) }))} />
				</div>
				<div className="flex justify-between items-center mt-2">
					<button className="px-3 py-1 border text-black" onClick={() => set_open_edit_prize_text(false)}>Close</button>
					<button className="px-3 py-1 bg-green text-white" disabled={loading_prize_text} onClick={update_edit_prize_text}>{loading_prize_text ? 'Updating' : 'Update'}</button>
				</div>
			</Modal>
			<Modal open={open_new_article} onClose={() => { set_open_new_article(false) }} center>
				<h2>Register New Latest Article</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Article Title" title="Required Title of new article">Title(*)</label>
					<textarea className="grow shrink border p-2" id="New Article Title" value={new_article.title} onChange={(e) => set_new_article((_prev) => ({ ..._prev, title: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Article Date" title="Required Date of new article">Date(*)</label>
					<input className="grow shrink border p-2" type="text" id="New Article Date" value={new_article.date} onChange={(e) => set_new_article((_prev) => ({ ..._prev, date: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Article Image" title="Required Image of new article">Image(*)</label>
					<textarea className="grow shrink border p-2" id="New Article Image" value={new_article.image} onChange={(e) => set_new_article((_prev) => ({ ..._prev, image: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="New Article Link" title="Required Link of new article">Link(*)</label>
					<textarea className="grow shrink border p-2" id="New Article Link" value={new_article.link} onChange={(e) => set_new_article((_prev) => ({ ..._prev, link: e.target.value }))} />
				</div>
				<div className="flex justify-between items-center mt-2">
					<button className="px-3 py-1 border text-black" onClick={() => set_open_new_article(false)}>Close</button>
					<button className="px-3 py-1 bg-green text-white" disabled={loading_articles} onClick={register_new_article}>{loading_articles ? 'Registering' : 'Register'}</button>
				</div>
			</Modal>
			<Modal open={open_edit_article} onClose={() => { set_open_edit_article(false) }} center>
				<h2>Update Latest Article {edit_article.id}</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Article Title" title="Required Title of new article">Title(*)</label>
					<textarea className="grow shrink border p-2" id="Edit Article Title" value={edit_article.title} onChange={(e) => set_edit_article((_prev) => ({ ..._prev, title: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Article Date" title="Required Date of new article">Date(*)</label>
					<input className="grow shrink border p-2" type="text" id="Edit Article Date" value={edit_article.date} onChange={(e) => set_edit_article((_prev) => ({ ..._prev, date: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Article Image" title="Required Image of new article">Image(*)</label>
					<textarea className="grow shrink border p-2" id="Edit Article Image" value={edit_article.image} onChange={(e) => set_edit_article((_prev) => ({ ..._prev, image: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Edit Article Link" title="Required Link of new article">Link(*)</label>
					<textarea className="grow shrink border p-2" id="Edit Article Link" value={edit_article.link} onChange={(e) => set_edit_article((_prev) => ({ ..._prev, link: e.target.value }))} />
				</div>
				<div className="flex justify-between items-center mt-2">
					<button className="px-3 py-1 border text-black" onClick={() => set_open_edit_article(false)}>Close</button>
					<button className="px-3 py-1 bg-green text-white" disabled={loading_articles} onClick={update_edit_article}>{loading_articles ? 'Updating' : 'Update'}</button>
				</div>
			</Modal>
			<Modal open={open_add_clc_avatar} onClose={() => { set_open_add_clc_avatar(false) }} center>
				<h2>Add CLC Avatar</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Name CLC Avatar" title="Required Name of clc avatar">Name</label>
					<input className="grow shrink border p-2" id="Name CLC Avatar" value={new_clc_avatar.name ?? ''} onChange={(e) => set_new_clc_avatar((_prev) => ({ ..._prev, name: e.target.value }))} />
				</div>
				<div className="flex items-center gap-2 mt-2">
					<label className="w-40 text-black" htmlFor="Image CLC Avatar" title="Required Image of clc avatar">Image(*)</label>
					<input className="grow shrink border p-2" id="Image CLC Avatar" type="file" multiple={false} accept="image/png, image/gif, image/jpeg" onChange={(e) => set_new_clc_avatar_file(e.target.files?.[0])} />
				</div>
				<div className="flex items-start gap-2 mt-2">
					{new_clc_avatar.thumbnail_path && <img className="grow-0 shrink-0 w-16 h-16" src={new_clc_avatar.thumbnail_path} alt="Resized" />}
					{new_clc_avatar.path ? <div className="grow shrink">
						<img className="w-full" src={new_clc_avatar.path} alt="Resized" />
					</div> : <span>Select Image</span>}
				</div>
				<div className="flex justify-between items-center mt-2">
					<button className="px-3 py-1 border text-black" onClick={() => set_open_add_clc_avatar(false)}>Close</button>
					<button className="px-3 py-1 bg-green text-white" disabled={loading_clc_avatars} onClick={add_clc_avatar}>{loading_clc_avatars ? 'Adding' : 'Add'}</button>
				</div>
			</Modal>
			<Modal open={open_gcs_modal} onClose={() => { set_open_gcs_modal(false) }} center>
				<h2>Update GCS</h2>
				<div className="py-2">
					<hr />
				</div>
				<div className="flex items-center gap-2">
					<label className="w-40 grow-0 shrink-0" htmlFor="CORS DOMAINS">Cors Domains:</label>
					<input id="CORS DOMAINS" className="grow shrink border p-2" type="text" placeholder="Add domains by seperate commas(,)" value={cors_domains} onChange={(e) => set_cors_domains(e.target.value)} />
					<button className="px-3 py-1 bg-green text-white" disabled={updating_cors} onClick={update_cors}>{loading_clc_avatars ? 'Updating' : 'Update CORS'}</button>
				</div>
				<div className="pl-40">
					<p className="ml-2 text-gray-500">Have to add <span className="text-blue-500" title="http://localhost:4001, http://localhost:4202">local</span> domains, <span className="text-blue-500" title="https://clc-api.clcv2deven.xyz, https://clc-manage.clcv2deven.xyz">dev site</span> domains and <span className="text-blue-500" title="https://api.chipleader.com, https://manage.chipleader.com">production site</span> domains</p>
				</div>
				<div className="w-full min-w-[360px] flex items-center gap-2 mt-2 overflow-auto">
					{gcs_json ? <JsonView data={gcs_json} /> : <span>Loading</span>}
				</div>
			</Modal>
		</>
	)
}