import { User } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
    user?: User;
}

const defaultState: AuthState = {
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: defaultState,
    reducers: {
        set_user: (state: AuthState, action: { payload: User }) => {
            state.user = action.payload;
        },
        clear_auth: (state: AuthState) => {
            state = defaultState;
        }
    },
})

export const {
    set_user,
    clear_auth,
} = authSlice.actions
export const authReducer = authSlice.reducer