import { LibraryType } from "@prisma/client"
import { ChangeEvent, useEffect, useState } from "react";
import { libraryService } from "../services/library.service";
import { Tabs } from "@clc-v2/uis";
import { IcAdd, IcUpload } from "@clc-v2/icons";
import Modal from "react-responsive-modal";
import { useAppSelector } from "../stores/hooks";
import { ILibraryWithVideos } from "@clc-v2/utilities";
import { VideoTagsPanel } from "../components/library/video-tags-panel";
import { VideoAuthorsPanel } from "../components/library/video-authors-panel";
import { LibraryPanel } from "../components/library/library-panel";
import { useListener } from "react-bus";
import { toast } from "react-toastify";

export const Library = () => {
    const { library_authors } = useAppSelector((state) => state.library);
    const [webinars, set_webinars] = useState<ILibraryWithVideos[]>([]);
    const [lessons, set_lessons] = useState<ILibraryWithVideos[]>([]);
    const [online_sessions, set_online_sessions] = useState<ILibraryWithVideos[]>([]);
    const [quizzes, set_quizzes] = useState<ILibraryWithVideos[]>([]);
    const [open_new_video_library, set_open_new_video_library] = useState<boolean>(false);
    const [loading_new_video_library, set_loading_new_video_library] = useState<boolean>(false);
    const [new_video_library_type, set_new_video_library_type] = useState<LibraryType>(LibraryType.webinar);
    const [new_video_library_unlock_required, set_new_video_library_unlock_required] = useState<number>(0);
    const [new_video_library_name, set_new_video_library_name] = useState<string>('');
    const [new_video_library_author_id_1, set_new_video_library_author_id_1] = useState<string>('');
    const [new_video_library_author_id_2, set_new_video_library_author_id_2] = useState<string>('');
    const [new_video_library_author_id_3, set_new_video_library_author_id_3] = useState<string>('');
    const [new_video_library_author_id_4, set_new_video_library_author_id_4] = useState<string>('');
    const [new_video_library_author_id_5, set_new_video_library_author_id_5] = useState<string>('');
    const [active_tab, set_active_tab] = useState<LibraryType>(LibraryType.webinar);
    const register_new_or_update_video_library = () => {
        set_loading_new_video_library(true);
        libraryService.registerNewOrUpdateVideoLibrary({
            id: '',
            type: new_video_library_type,
            unlock_required: new_video_library_unlock_required,
            name: new_video_library_name,
            library_author_id_1: new_video_library_author_id_1,
            library_author_id_2: new_video_library_author_id_2,
            library_author_id_3: new_video_library_author_id_3,
            library_author_id_4: new_video_library_author_id_4,
            library_author_id_5: new_video_library_author_id_5,
        }).then((_new_video_library) => {
            if (new_video_library_type === LibraryType.webinar) {
                if (webinars.map(({ name }) => name).includes(_new_video_library.name)) {
                    set_webinars((_prev_webinars) => _prev_webinars.map((_prev_webinar) => _prev_webinar.name === _new_video_library.name ? ({ ..._prev_webinar, ..._new_video_library }) : _prev_webinar));
                } else {
                    set_webinars((_prev) => [..._prev, {
                        ..._new_video_library,
                        library_videos: []
                    }]);
                }
            } else if (new_video_library_type === LibraryType.lesson) {
                if (lessons.map(({ name }) => name).includes(_new_video_library.name)) {
                    set_lessons((_prev_lessons) => _prev_lessons.map((_prev_lesson) => _prev_lesson.name === _new_video_library.name ? ({ ..._prev_lesson, ..._new_video_library }) : _prev_lesson))
                } else {
                    set_lessons((_prev) => [..._prev, {
                        ..._new_video_library,
                        library_videos: []
                    }]);
                }
            } else if (new_video_library_type === LibraryType.online_session) {
                if (online_sessions.map(({ name }) => name).includes(_new_video_library.name)) {
                    set_online_sessions((_prev_online_sessions) => _prev_online_sessions.map((_prev_online_session) => _prev_online_session.name === _new_video_library.name ? ({ ..._prev_online_session, ..._new_video_library }) : _prev_online_session))
                } else {
                    set_online_sessions((_prev) => [..._prev, {
                        ..._new_video_library,
                        library_videos: []
                    }]);
                }
            } else if (new_video_library_type === LibraryType.quiz) {
                if (quizzes.map(({ name }) => name).includes(_new_video_library.name)) {
                    set_quizzes((_prev_quizzes) => _prev_quizzes.map((_prev_quiz) => _prev_quiz.name === _new_video_library.name ? ({ ..._prev_quiz, ..._new_video_library }) : _prev_quiz))
                } else {
                    set_quizzes((_prev) => [..._prev, {
                        ..._new_video_library,
                        library_videos: []
                    }]);
                }
            }
            toast.success(`Library (${_new_video_library.id}) is registered`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_new_video_library(false);
            set_new_video_library_unlock_required(0);
            set_new_video_library_name('');
            set_new_video_library_author_id_1('');
            set_new_video_library_author_id_2('');
        });
    }
    const get_all_webinars = () => {
        libraryService.getAllWebinars().then((_webinars) => {
            set_webinars(_webinars);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_all_lessons = () => {
        libraryService.getAllLessons().then((_lessons) => {
            set_lessons(_lessons);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_all_online_sessions = () => {
        libraryService.getAllOnlineSessions().then((_online_sessions) => {
            set_online_sessions(_online_sessions);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_all_quizzes = () => {
        libraryService.getAllQuizzes().then((_quizzes) => {
            set_quizzes(_quizzes);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        })
    }
    const library_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];
            // set_loading_topics(true);
            libraryService.uploadLibraryCSV(file).then((success) => {
                get_all_webinars();
                get_all_lessons();
                get_all_online_sessions();
                get_all_quizzes();
                toast.warn(`Library csv file is uploaded successfully`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                // set_loading_topics(false);
            });
        }
    }
    const library_video_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];
            libraryService.uploadLibraryVideoCSV(file).then((success) => {
                get_all_webinars();
                get_all_lessons();
                get_all_online_sessions();
                get_all_quizzes();
                toast.warn(`Library csv file(v1) is uploaded successfully`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        }
    }
    useListener('refetch-webinars', get_all_webinars);
    useListener('refetch-lessons', get_all_lessons);
    useListener('refetch-online-sessions', get_all_online_sessions);
    useListener('refetch-quizzes', get_all_quizzes);
    useEffect(() => {
        get_all_webinars();
        get_all_lessons();
        get_all_online_sessions();
        get_all_quizzes();
    }, []);
    return (
        <div>
            <div className="flex items-start gap-2">
                <VideoTagsPanel />
            </div>
            <div className="flex items-start gap-2">
                <VideoAuthorsPanel />
            </div>
            <div className="flex items-start gap-6 mt-2 px-4">
                <div className="w-40 grow-0 shrink-0 flex items-center gap-2">
                    <label className="text-white" htmlFor="Videos List">Library Videos</label>
                    <div title="Add New or Update Library" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" onClick={() => set_open_new_video_library(true)}>
                        <IcAdd />
                    </div>
                    <label title="2. Upload CSV of Library" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" htmlFor="library-csv-file-upload">
                        <IcUpload />
                    </label>
                    <input id="library-csv-file-upload" type="file" hidden multiple={false} accept=".csv" onChange={library_csv_upload_handler} />
                    <label title="3. Upload CSV of Library Video" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" htmlFor="library-video-csv-file-upload">
                        <IcUpload />
                    </label>
                    <input id="library-video-csv-file-upload" type="file" hidden multiple={false} accept=".csv" onChange={library_video_csv_upload_handler} />
                </div>
                <div className="shrink grow flex flex-col gap-1">
                    <Tabs
                        options={[
                            {
                                label: 'Webinars', value: LibraryType.webinar, description: 'Webinars'
                            },
                            {
                                label: 'Lessons', value: LibraryType.lesson, description: 'Lessons'
                            },
                            {
                                label: 'Online Sessions', value: LibraryType.online_session, description: 'Online Sessions'
                            },
                            {
                                label: 'Quizzes', value: LibraryType.quiz, description: 'Quizzes'
                            }
                        ]}
                        activeValue={active_tab}
                        onTabChangeHandler={(_new_value) => new Promise((resolve, reject) => { set_active_tab(_new_value as LibraryType); resolve(true); })}
                    />
                    {
                        active_tab === LibraryType.webinar && (
                            <LibraryPanel libraries={webinars} />
                        )
                    }
                    {
                        active_tab === LibraryType.lesson && (
                            <LibraryPanel libraries={lessons} />
                        )
                    }
                    {
                        active_tab === LibraryType.online_session && (
                            <LibraryPanel libraries={online_sessions} />
                        )
                    }
                    {
                        active_tab === LibraryType.quiz && (
                            <LibraryPanel libraries={quizzes} />
                        )
                    }
                </div>
            </div>
            <Modal open={open_new_video_library} onClose={() => set_open_new_video_library(false)} center>
                <h2>Register New Video Library</h2>
                <div className="py-2">
                    <hr />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Type">Library Type(*)</label>
                    <select className="border p-2" id="New Video Library Type" value={new_video_library_type} onChange={(e) => set_new_video_library_type(e.target.value as LibraryType)}>
                        {Object.entries(LibraryType).map((library_key) => (
                            <option key={library_key[1]} value={library_key[1]}>{library_key[0]}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Unlock Required Chips" title="Required earning chips to unlock library">Library Unlock(*)</label>
                    <input className="border p-2" type="number" id="New Video Library Unlock Required Chips" value={new_video_library_unlock_required} onChange={(e) => set_new_video_library_unlock_required(parseInt(e.target.value))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Name">Library Name</label>
                    <input className="border p-2" type="text" id="New Video Library Name" value={new_video_library_name} onChange={(e) => set_new_video_library_name(e.target.value)} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Author1">Library Author1(*)</label>
                    <select className="shrink grow border p-2" id="New Video Library Author1" value={new_video_library_author_id_1} onChange={(e) => set_new_video_library_author_id_1(e.target.value)}>
                        <option defaultChecked>No Select</option>
                        {(library_authors ?? []).map((author) => (
                            <option key={author.id} value={author.id}>{author.first_name} {author.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Author2">Library Author2</label>
                    <select className="shrink grow border p-2" id="New Video Library Author2" value={new_video_library_author_id_2} onChange={(e) => set_new_video_library_author_id_2(e.target.value)}>
                        <option defaultChecked>No Select</option>
                        {(library_authors ?? []).map((author) => (
                            <option key={author.id} value={author.id}>{author.first_name} {author.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Author3">Library Author3</label>
                    <select className="shrink grow border p-2" id="New Video Library Author3" value={new_video_library_author_id_3} onChange={(e) => set_new_video_library_author_id_3(e.target.value)}>
                        <option defaultChecked>No Select</option>
                        {(library_authors ?? []).map((author) => (
                            <option key={author.id} value={author.id}>{author.first_name} {author.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Author4">Library Author4</label>
                    <select className="shrink grow border p-2" id="New Video Library Author4" value={new_video_library_author_id_4} onChange={(e) => set_new_video_library_author_id_4(e.target.value)}>
                        <option defaultChecked>No Select</option>
                        {(library_authors ?? []).map((author) => (
                            <option key={author.id} value={author.id}>{author.first_name} {author.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="New Video Library Author5">Library Author5</label>
                    <select className="shrink grow border p-2" id="New Video Library Author5" value={new_video_library_author_id_5} onChange={(e) => set_new_video_library_author_id_5(e.target.value)}>
                        <option defaultChecked>No Select</option>
                        {(library_authors ?? []).map((author) => (
                            <option key={author.id} value={author.id}>{author.first_name} {author.last_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <button className="px-3 py-1 border text-black" onClick={() => set_open_new_video_library(false)}>Close</button>
                    <button className="px-3 py-1 bg-green text-white" disabled={loading_new_video_library} onClick={register_new_or_update_video_library}>{loading_new_video_library ? 'Registering' : 'Register'}</button>
                </div>
            </Modal>
        </div >
    )
}