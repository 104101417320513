import { ChangeEvent, Fragment, useEffect, useState } from "react"
import { paymentService } from "../services/payment.service";
import { CBCustomer, CBItemPrice } from "@prisma/client";
import { IcUpload } from "@clc-v2/icons";
import Modal from "react-responsive-modal";
import lodash from "lodash";
import { CBItemPriceWithItem } from "@clc-v2/utilities";
import { toast } from "react-toastify";

export const CBPayment = () => {
    const [customers, set_customers] = useState<CBCustomer[]>([]);
    const [grouped_prices, set_grouped_prices] = useState<Record<string, CBItemPriceWithItem[]>>({});
    const [open_editing_price, set_open_editing_price] = useState<boolean>(false);
    const [editing_price, set_editing_price] = useState<Omit<CBItemPrice, "item_id" | "item" | "created_at" | "updated_at">>({
        id: "",
        item_type: "",
        name: "",
        external_name: "",
        description: "",
        status: "",
        period: 0,
        period_unit: "",
        price: 0,
        currency_code: "",
        self_description: "",
        monthly_price: 0,
        monthly_price_full: 0,
        saved_money_text: "",
        bill_text: "",
        tip: "",
    });
    const [updating_editing_price, set_updating_editing_price] = useState<boolean>(false);
    const update_customers = () => {
        paymentService.getCustomers().then((_customers) => {
            set_customers(_customers)
        })
    }
    const update_prices = () => {
        paymentService.getAllItemPrices().then((_prices) => {
            const _grouped_prices = lodash.groupBy(_prices, (_price) => _price.item.id);
            set_grouped_prices(_grouped_prices);
        })
    }
    const cb_payment_backup_v1_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];
            // set_loading_users(true);
            paymentService.uploadCBPaymentCSV(file).then((success) => {
                update_customers();
                toast.success(`CB Payment backup csv file(v1) is uploaded successfully`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                // set_loading_users(false);
            });
        }
    }
    const update_price_handler = () => {
        set_updating_editing_price(true);
        paymentService.updatePriceHandler(editing_price.id, editing_price).then((_updated_price) => {
            update_prices();
            toast.success(`Price (${_updated_price.id}) is updated`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }).catch((err_msg) => {
            toast.warn(err_msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }).finally(() => {
            set_updating_editing_price(false);
        });
    }
    useEffect(() => {
        update_customers();
        update_prices();
    }, []);
    return (
        <div className="w-full">
            <label title="Upload CSV of CB Payment Data from backup v1" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" htmlFor="cb-payment-csv-file-upload">
                <IcUpload />
            </label>
            <input id="cb-payment-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={cb_payment_backup_v1_csv_upload_handler} />
            <div className="h-96 overflow-auto">
                {customers.map((customer) => (
                    <div className="w-full grid grid-cols-12 gap-1 text-white" key={customer.id}>
                        <div className="col-span-2">{customer.id}</div>
                        <div className="col-span-2">{customer.user_id}</div>
                        <div className="col-span-2">{customer.email}</div>
                        <div className="col-span-2">{customer.card_status}</div>
                        <div className="col-span-2">{customer.preferred_currency_code}</div>
                    </div>
                ))}
            </div>
            {Object.keys(grouped_prices).map((item_name, id) => (
                <Fragment key={id}>
                    <p className="text-2xl text-white">{item_name}</p>
                    <div className="grid grid-cols-3 gap-6 text-white">
                        {grouped_prices[item_name].map((price) => (
                            <div id={price.id} className="flex flex-col border px-6 py-4 relative" key={price.id}>
                                <button className="absolute right-4 top-4 text-white" onClick={() => {
                                    set_editing_price({
                                        id: price.id,
                                        item_type: price.item_type,
                                        name: price.name,
                                        external_name: price.external_name,
                                        description: price.description,
                                        status: price.status,
                                        period: price.period,
                                        period_unit: price.period_unit,
                                        price: price.price,
                                        currency_code: price.currency_code,
                                        self_description: price.self_description,
                                        monthly_price: price.monthly_price,
                                        monthly_price_full: price.monthly_price_full,
                                        saved_money_text: price.saved_money_text,
                                        bill_text: price.bill_text,
                                        tip: price.tip,
                                    });
                                    set_open_editing_price(true);
                                }}>Edit</button>
                                <span>Id: {price.id}</span>
                                <span>Item Type: {price.item_type}</span>
                                <span>Name: {price.name}</span>
                                <span>External Name: {price.external_name}</span>
                                <span>Description: {price.description}</span>
                                <span>Status: {price.status}</span>
                                <span>Period: {price.period}</span>
                                <span>Period Unit: {price.period_unit}</span>
                                <span>Price: {price.price}</span>
                                <span>Currency: {price.currency_code}</span>
                                <span>Self Description: {price.self_description}</span>
                                <span>Monthly Price: {price.monthly_price}</span>
                                <span>Monthly Price Full: {price.monthly_price_full}</span>
                                <span>Saved Money Text: {price.saved_money_text}</span>
                                <span>Bill Text: {price.bill_text}</span>
                                <span>Tip: {price.tip}</span>
                            </div>
                        ))}
                    </div>
                </Fragment>
            ))}
            <Modal open={open_editing_price} onClose={() => set_open_editing_price(false)} center>
                <h2>Update Price</h2>
                <div className="py-2">
                    <hr />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="ID">Id</label>
                    <input className="border p-2" type="text" id="ID" value={editing_price.id} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Item Type">Item Type</label>
                    <input className="border p-2" type="text" id="Item Type" value={editing_price.item_type} readOnly />
                </div>
                <div className="flex items-start gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Name">Name</label>
                    <input className="border p-2" type="text" id="Name" value={editing_price.name} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="External Name">Exernal Name</label>
                    <input className="border p-2" type="text" id="External Name" value={editing_price.external_name} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Description">Description</label>
                    <input className="border p-2" type="number" id="Description" value={editing_price.description} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Status">status</label>
                    <input className="border p-2" type="text" id="Status" value={editing_price.status} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Price">Price</label>
                    <input className="border p-2" type="text" id="Price" value={editing_price.price} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Currency Code">Currency Code</label>
                    <input className="border p-2" type="text" id="Currency Code" value={editing_price.currency_code} readOnly />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Self Description">Self Description</label>
                    <input className="border p-2" type="text" id="Self Description" value={editing_price.self_description} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, self_description: e.target.value }))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Monthly Price">Monthly Price</label>
                    <input className="border p-2" type="number" id="Monthly Price" value={editing_price.monthly_price} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, monthly_price: parseFloat(e.target.value) }))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Monthly Price Full">Monthly Price Full</label>
                    <input className="border p-2" type="number" id="Monthly Price Full" value={editing_price.monthly_price_full} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, monthly_price_full: parseFloat(e.target.value) }))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Saved Money Text">Saved Money Text</label>
                    <input className="border p-2" type="text" id="Saved Money Text" value={editing_price.saved_money_text} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, saved_money_text: e.target.value }))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Bill Text">Bill Text</label>
                    <input className="border p-2" type="text" id="Bill Text" value={editing_price.bill_text} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, bill_text: e.target.value }))} />
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <label className="w-40 text-black" htmlFor="Tip">Tip</label>
                    <input className="border p-2" type="text" id="Tip" value={editing_price.tip} onChange={(e) => set_editing_price((_prev) => ({ ..._prev, tip: e.target.value }))} />
                </div>
                <div className="flex justify-between items-center mt-6">
                    <button className="px-3 py-1 border text-black" onClick={() => set_open_editing_price(false)}>Cancel</button>
                    <button className="px-3 py-1 bg-green text-white" disabled={updating_editing_price} onClick={() => update_price_handler()}>{updating_editing_price ? 'Updating' : 'Update'}</button>
                </div>
            </Modal>
        </div>
    )
}