import { IcAdd, IcUpload } from "@clc-v2/icons"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { libraryService } from "../../services/library.service";
import { ChangeEvent, useEffect, useState } from "react";
import { set_library_authors } from "../../stores/library";
import { LibraryAuthor } from "@prisma/client";
import { Author, Avatar } from "@clc-v2/uis";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import { imageService } from "../../services/image.service";
import { splitFileName } from "@clc-v2/utilities";

const AuthorCard = (props: { author: LibraryAuthor, onClick?: () => void }) => {
  return (
    <div className="flex flex-col items-center text-white border rounded-2xl px-4 py-3" onClick={props.onClick}>
      <img className="w-16 h-16" src={props.author.thumbnail_picture === '' ? props.author.picture : props.author.thumbnail_picture} alt="" />
      <p className="mt-4">{props.author.first_name} {props.author.last_name}</p>
      <span>({props.author.id})</span>
    </div>
  )
}

export const VideoAuthorsPanel = () => {
  const dispatch = useAppDispatch();
  const { library_authors } = useAppSelector((state) => state.library);
  const [loading_authors, set_loading_authors] = useState<boolean>(false);
  const [open_new_author, set_open_new_author] = useState<boolean>(false);
  const [new_author, set_new_author] = useState<Omit<LibraryAuthor, "created_at" | "updated_at">>({
    id: '',
    first_name: '',
    last_name: '',
    picture: '',
    thumbnail_picture: ''
  });
  const [new_author_avatar_file, set_new_author_avatar_file] = useState<File>();
  const [new_author_avatar_file_64_64, set_new_author_avatar_file_64_64] = useState<File>();
  const [open_edit_author, set_open_edit_author] = useState<boolean>(false);
  const [edit_author, set_edit_author] = useState<Omit<LibraryAuthor, "created_at" | "updated_at">>({
    id: '',
    first_name: '',
    last_name: '',
    picture: '',
    thumbnail_picture: ''
  });
  const [edit_author_avatar_file, set_edit_author_avatar_file] = useState<File>();
  const [edit_author_avatar_file_64_64, set_edit_author_avatar_file_64_64] = useState<File>();
  const get_all_library_authors = () => {
    libraryService.getAllAuthors().then((_authors) => {
      dispatch(
        set_library_authors(_authors)
      )
    }).catch((err_msg: string) => {
      console.error(err_msg);
    });
  }
  const upload_author_avatar = (mode: 'new' | 'edit', callback: (path?: string, thumbnail_path?: string) => void) => {
    if (mode === 'new' && new_author_avatar_file && new_author_avatar_file_64_64) {
      set_loading_authors(true);
      const timestamp = new Date().getTime();
      const { name, extension } = splitFileName(new_author_avatar_file.name.trim());
      const file_name = `${name}-${timestamp}` + (extension ? `.${extension}` : '');
      const file_name_64 = `${name}-${timestamp}_64_64` + (extension ? `.${extension}` : '');
      imageService.getSignedURL('author-avatars', file_name, new_author_avatar_file.type).then(({ signed_url: _signed_url, path }) => {
        imageService.uploadFileToGCS(_signed_url, new_author_avatar_file).then(() => {
          imageService.getSignedURL('author-avatars', file_name_64, new_author_avatar_file_64_64.type).then(({ signed_url: _signed_url, path: thumbnail_path }) => {
            imageService.uploadFileToGCS(_signed_url, new_author_avatar_file_64_64).then(() => {
              if (callback === register_new_author) {
                set_new_author((_prev) => ({
                  ..._prev,
                  picture: path,
                  thumbnail_picture: thumbnail_path,
                }));
              } else {
                set_edit_author((_prev) => ({
                  ..._prev,
                  picture: path,
                  thumbnail_picture: thumbnail_path,
                }));
              }
              callback(path, thumbnail_path);
            }).catch((err) => {
              alert(err);
            })
          }).catch((err_msg: string) => {
            alert(err_msg);
          })
        }).catch((err) => {
          alert(err);
        })
      }).catch((err_msg: string) => {
        alert(err_msg);
      })
    } else if (mode === 'edit' && edit_author_avatar_file && edit_author_avatar_file_64_64) {
      set_loading_authors(true);
      const timestamp = new Date().getTime();
      const { name, extension } = splitFileName(edit_author_avatar_file.name.trim());
      const file_name = `${name}-${timestamp}` + (extension ? `.${extension}` : '');
      const file_name_64 = `${name}-${timestamp}_64_64` + (extension ? `.${extension}` : '');
      imageService.getSignedURL('author-avatars', file_name, edit_author_avatar_file.type).then(({ signed_url: _signed_url, path }) => {
        imageService.uploadFileToGCS(_signed_url, edit_author_avatar_file).then(() => {
          imageService.getSignedURL('author-avatars', file_name_64, edit_author_avatar_file_64_64.type).then(({ signed_url: _signed_url, path: thumbnail_path }) => {
            imageService.uploadFileToGCS(_signed_url, edit_author_avatar_file_64_64).then(() => {
              if (callback === register_new_author) {
                set_new_author((_prev) => ({
                  ..._prev,
                  picture: path,
                  thumbnail_picture: thumbnail_path,
                }));
              } else {
                set_edit_author((_prev) => ({
                  ..._prev,
                  picture: path,
                  thumbnail_picture: thumbnail_path,
                }));
              }
              callback(path, thumbnail_path);
            }).catch((err) => {
              alert(err);
            })
          }).catch((err_msg: string) => {
            alert(err_msg);
          })
        }).catch((err) => {
          alert(err);
        })
      }).catch((err_msg: string) => {
        alert(err_msg);
      })
    } else {
      callback()
    }
  }
  const register_new_author = (path?: string, thumbnail_path?: string) => {
    set_loading_authors(true);
    libraryService.registerNewOrUpdateAuthor({
      ...new_author,
      picture: path ?? new_author.picture,
      thumbnail_picture: thumbnail_path ?? (new_author.thumbnail_picture === '' ? new_author.picture : new_author.thumbnail_picture),
    }).then((_new_author) => {
      dispatch(
        set_library_authors(
          (library_authors ?? []).map(({ id }) => id).includes(_new_author.id) ?
            (library_authors ?? []).map((_prev_author) => _prev_author.id === _new_author.id ? _new_author : _prev_author) :
            [...(library_authors ?? []), _new_author]
        )
      )
      toast.success(`New author (${_new_author.id}) is registered`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_authors(false);
    });
  }
  const update_edit_author = (path?: string, thumbnail_path?: string) => {
    set_loading_authors(true);
    libraryService.registerNewOrUpdateAuthor({
      ...edit_author,
      picture: path ?? edit_author.picture,
      thumbnail_picture: thumbnail_path ?? (edit_author.thumbnail_picture === '' ? edit_author.picture : edit_author.thumbnail_picture),
    }).then((_updated_author) => {
      dispatch(
        set_library_authors(
          (library_authors ?? []).map(({ id }) => id).includes(_updated_author.id) ?
            (library_authors ?? []).map((_prev_author) => _prev_author.id === _updated_author.id ? _updated_author : _prev_author) :
            [...(library_authors ?? []), _updated_author]
        )
      )
      toast.success(`Author (${_updated_author.id}) is updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_authors(false);
    });
  }
  const delete_author = (author_id: string) => {
    libraryService.removeAuthor(author_id).then((_removed_author) => {
      dispatch(
        set_library_authors((library_authors ?? []).filter((author) => author.id !== _removed_author.id))
      )
      toast.success(`Author (${_removed_author.id}) is deleted`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    })
  }
  const library_author_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      // set_loading_topics(true);
      libraryService.uploadLibraryAuthorCSV(file).then((success) => {
        get_all_library_authors();
        toast.success(`Author csv file is uploaded successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  useEffect(() => {
    get_all_library_authors();
  }, []);
  useEffect(() => {
    if (new_author_avatar_file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 64;
          canvas.height = 64;
          ctx?.drawImage(img, 0, 0, 64, 64);
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedImageFile = new File([blob], new_author_avatar_file.name, { type: new_author_avatar_file.type });
              set_new_author_avatar_file_64_64(resizedImageFile);
            }
          }, new_author_avatar_file.type);
        };
        img.src = e.target?.result as string;
        set_new_author((_prev) => ({ ..._prev, picture: e.target?.result as string }));
      };
      reader.readAsDataURL(new_author_avatar_file);
    } else {
      set_new_author((_prev) => ({ ..._prev, picture: '' }))
    }
  }, [new_author_avatar_file]);
  useEffect(() => {
    if (new_author_avatar_file_64_64) {
      const reader = new FileReader();
      reader.onload = (e) => {
        set_new_author((_prev) => ({ ..._prev, thumbnail_picture: e.target?.result as string }));
      };
      reader.readAsDataURL(new_author_avatar_file_64_64);
    } else {
      set_new_author((_prev) => ({ ..._prev, thumbnail_picture: '' }))
    }
  }, [new_author_avatar_file_64_64]);
  useEffect(() => {
    if (edit_author_avatar_file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 64;
          canvas.height = 64;
          ctx?.drawImage(img, 0, 0, 64, 64);
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedImageFile = new File([blob], edit_author_avatar_file.name, { type: edit_author_avatar_file.type });
              set_edit_author_avatar_file_64_64(resizedImageFile);
            }
          }, edit_author_avatar_file.type);
        };
        img.src = e.target?.result as string;
        set_edit_author((_prev) => ({ ..._prev, picture: e.target?.result as string }));
      };
      reader.readAsDataURL(edit_author_avatar_file);
    } else {
      set_edit_author((_prev) => ({ ..._prev, picture: '' }))
    }
  }, [edit_author_avatar_file]);
  useEffect(() => {
    if (edit_author_avatar_file_64_64) {
      const reader = new FileReader();
      reader.onload = (e) => {
        set_edit_author((_prev) => ({ ..._prev, thumbnail_picture: e.target?.result as string }));
      };
      reader.readAsDataURL(edit_author_avatar_file_64_64);
    } else {
      set_edit_author((_prev) => ({ ..._prev, thumbnail_picture: '' }))
    }
  }, [edit_author_avatar_file_64_64]);
  return (
    <div className="w-full flex items-start gap-6 px-4 py-4 border-b">
      <div className="w-40 grow-0 shrink-0 text-white flex items-center gap-2">
        <p>Authors({(library_authors ?? []).length})</p>
        <div className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" onClick={() => set_open_new_author(true)}>
          <IcAdd />
        </div>
        <label title="1. Upload CSV of Authors" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" htmlFor="library-authors-csv-file-upload">
          <IcUpload />
        </label>
        <input id="library-authors-csv-file-upload" type="file" hidden multiple={false} accept=".csv" onChange={library_author_csv_upload_handler} />
      </div>
      <div className="w-full grid grid-cols-5 gap-4">
        {(library_authors ?? []).map((author, id) => (
          <div key={id}>
            <AuthorCard
              author={author}
              onClick={() => {
                set_edit_author(author);
                set_open_edit_author(true);
              }}
            />
          </div>
        ))}
      </div>
      <Modal open={open_new_author} onClose={() => set_open_new_author(false)} center>
        <h2>Register New Video Author</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Video Author ID" title="Required id of author">Author ID(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author ID" value={new_author.id} onChange={(e) => set_new_author((_prev) => ({ ..._prev, id: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Video Author FName" title="Required first name of author">Author First Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author FName" value={new_author.first_name} onChange={(e) => set_new_author((_prev) => ({ ..._prev, first_name: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Video Author LName" title="Required first name of author">Author Last Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author LName" value={new_author.last_name} onChange={(e) => set_new_author((_prev) => ({ ..._prev, last_name: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Video Author Picture" title="Required first name of author">Author Picture(*)</label>
          <input className="border p-2" type="text" id="New Video Author Picture" value={new_author.picture} onChange={(e) => set_new_author((_prev) => ({ ..._prev, picture: e.target.value }))} placeholder="https://i.pravatar.cc/150" />
          <label title="Upload avatar to gcs" className="rounded-full w-5 h-5 bg-black flex justify-center items-center cursor-pointer" htmlFor="New Video Author Avatar Upload">
            <IcUpload />
          </label>
          <input id="New Video Author Avatar Upload" type="file" hidden multiple={false} accept="image/png, image/gif, image/jpeg" onChange={(e) => set_new_author_avatar_file(e.target.files?.[0])} />
          <div className="w-16 h-16">
            <Avatar picture={new_author.picture} />
          </div>
          <div className="w-16 h-16">
            <Avatar picture={new_author.thumbnail_picture === '' ? new_author.picture : new_author.thumbnail_picture} />
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_author(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_authors} onClick={() => upload_author_avatar('new', register_new_author)}>{loading_authors ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
      <Modal open={open_edit_author} onClose={() => set_open_edit_author(false)} center>
        <h2>Update Video Author</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Update Video Author ID" title="Required id of author">Author ID(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author ID" value={edit_author.id} onChange={(e) => set_edit_author((_prev) => ({ ..._prev, id: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Update Video Author FName" title="Required first name of author">Author First Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author FName" value={edit_author.first_name} onChange={(e) => set_edit_author((_prev) => ({ ..._prev, first_name: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Update Video Author LName" title="Required first name of author">Author Last Name(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Video Author LName" value={edit_author.last_name} onChange={(e) => set_edit_author((_prev) => ({ ..._prev, last_name: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Update Video Author Picture" title="Required first name of author">Author Picture(*)</label>
          <input className="border p-2" type="text" id="Update Video Author Picture" value={edit_author.picture} onChange={(e) => set_edit_author((_prev) => ({ ..._prev, picture: e.target.value }))} placeholder="https://i.pravatar.cc/150" />
          <label title="Upload avatar to gcs" className="rounded-full w-5 h-5 bg-black flex justify-center items-center cursor-pointer" htmlFor="Update Video Author Avatar Upload">
            <IcUpload />
          </label>
          <input id="Update Video Author Avatar Upload" type="file" hidden multiple={false} accept="image/png, image/gif, image/jpeg" onChange={(e) => set_edit_author_avatar_file(e.target.files?.[0])} />
          <div className="w-16 h-16">
            <Avatar picture={edit_author.picture} />
          </div>
          <div className="w-16 h-16">
            <Avatar picture={edit_author.thumbnail_picture === '' ? edit_author.picture : edit_author.thumbnail_picture} />
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_author(false)}>Close</button>
          <div className="flex items-center gap-4">
            <button className="px-3 py-1 bg-red-500 text-white" onClick={() => delete_author(edit_author.id)}>Delete</button>
            <button className="px-3 py-1 bg-green text-white" disabled={loading_authors} onClick={() => upload_author_avatar('edit', update_edit_author)}>{loading_authors ? 'Updating' : 'Update'}</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}