import { useAppSelector } from "../stores/hooks";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { ADMIN_ROLE, ROLES, checkRole } from "@clc-v2/utilities";
import classNames from "classnames";
import storage from "redux-persist/lib/storage";
import { STORE_KEY } from "../stores";
import Cookies from "js-cookie";

const loginPath = process.env.NX_AUTH_DOMAIN ?? '' + '/auth/login';
export const AuthCookieName = process.env.NX_AUTH_COOKIE_NAME ?? '';
export const loginUrl = `${loginPath}?redirect=${encodeURIComponent(window.location.href)}`;

const menus: Array<{
    title: string;
    path: string;
    permission: number;
}> = [
        { title: 'Topic & Lesson & Question', path: '/topic-lesson-question', permission: ROLES.MANAGE_PAGE_TLQ_VIEW },
        { title: 'Library', path: '/library', permission: ROLES.MANAGE_PAGE_LIBRARY_VIEW },
        { title: 'Users', path: '/users', permission: ROLES.MANAGE_PAGE_USERS_VIEW },
        { title: 'Customer Earning', path: '/customer-earning', permission: ROLES.MANAGE_PAGE_USERS_VIEW },
        { title: 'Playing History', path: '/playing-history', permission: ROLES.MANAGE_PAGE_USERS_VIEW },
        { title: 'CB Payment', path: '/cb-payment', permission: ROLES.MANAGE_PAGE_CB_PAYMENT_VIEW },
        { title: 'Data', path: '/data', permission: ROLES.MANAGE_PAGE_CLC_DATA_VIEW },
        { title: 'Reward', path: '/reward', permission: ADMIN_ROLE },
        { title: 'Daily Report', path: '/report', permission: ADMIN_ROLE },
    ]

export const Layout1 = () => {
    const { user } = useAppSelector((state) => state.auth);
    const { pathname } = useLocation();
    const sign_out = () => {
        storage.removeItem(`persist:${STORE_KEY}`);
        Cookies.remove(AuthCookieName);
        setTimeout(() => {
            window.location.href = loginUrl;
        }, 500);
    }
    return (
        <div className="max-w-screen min-h-screen bg-black-dark">
            <div className="flex justify-between bg-secondary-grey px-4 py-2">
                <h1 className="text-white uppercase">CLC Management</h1>
                <div className="flex justify-center items-center gap-4">
                    <span className="text-white cursor-pointer" onClick={sign_out}>SIGN OUT</span>
                </div>
            </div>
            <div className="relative min-h-[calc(100vh-40px)]">
                <div className="absolute left-0 top-0 w-60 h-full bg-primary-dark-grey">
                    {user ? (
                        menus.filter(({ permission }) => checkRole(permission, user?.role)).map((menu) => {
                            return (
                                <NavLink to={menu.path} key={menu.path}>
                                    <div key={menu.path} className={classNames(
                                        "px-2 py-1 cursor-pointer",
                                        {
                                            "bg-primary-golden text-primary-carbon": pathname === menu.path,
                                            "text-primary-golden hover:bg-primary-golden hover:text-primary-carbon": pathname !== menu.path,
                                        }
                                    )}>
                                        <span>{menu.title}</span>
                                    </div>
                                </NavLink>
                            )
                        })
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <div className="ml-60 px-4 py-2">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}