import axios, { type AxiosError } from 'axios';
import { User } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from '../config';

class AccountService {
    async selfAccount(selfId: string) {
        return new Promise<User>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/${selfId}`).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    // async updateLastCheckedAtNewHands() {
    //     return new Promise<User>((resolve, reject) => {
    //         axios.post(`${API_BACKEND_ENDPOINT}/user/update-last-checked-at-new-hands`).then((res: { data: User }) => {
    //             resolve(res.data);
    //         }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
    //             if (err.response) {
    //                 reject(err.response.data.message);
    //             } else {
    //                 reject(err.message);
    //             }
    //         });
    //     })
    // }
}

export const accountService = new AccountService();
