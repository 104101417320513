import { ILibraryWithVideos, LibraryVideoWithTag } from "@clc-v2/utilities"
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { useState } from "react";
import { CardVideo } from "@clc-v2/uis";
import Modal from "react-responsive-modal";
import { Library, LibraryType, LibraryVideo, LibraryVideoAndVideoTag } from "@prisma/client";
import { libraryService } from "../../services/library.service";
import { useBus } from "react-bus";
import classNames from "classnames";
import { toast } from "react-toastify";

const Row = (props: { library: ILibraryWithVideos }) => {
  const bus = useBus();
  const [opened, set_opened] = useState<boolean>(false);
  const { library_authors, video_tags } = useAppSelector((state) => state.library);
  const [loading_library, set_loading_library] = useState<boolean>(false);
  const [open_edit_library, set_open_edit_library] = useState<boolean>(false);
  const [edit_library, set_edit_library] = useState<Library>({
    id: '',
    library_author_id_1: '',
    library_author_id_2: '',
    library_author_id_3: '',
    library_author_id_4: '',
    library_author_id_5: '',
    name: '',
    type: LibraryType.webinar,
    unlock_required: 0
  });
  const [loading_library_video, set_loading_library_video] = useState<boolean>(false);
  const [open_new_library_video, set_open_new_library_video] = useState<boolean>(false);
  const [new_library_video, set_new_library_video] = useState<LibraryVideoWithTag>({
    id: '',
    date: '',
    description: '',
    featured: false,
    latest: false,
    library_id: '',
    related_videos: [],
    title: '',
    unlock_required: 0,
    video_duration: 0,
    video_thumbnail: '',
    video_url: '',
    library_video_and_video_tag: [],
    reward_chip: 0,
    difficulty: 1,
  });
  const [open_edit_library_video, set_open_edit_library_video] = useState<boolean>(false);
  const [edit_library_video, set_edit_library_video] = useState<LibraryVideoWithTag>({
    id: '',
    date: '',
    description: '',
    featured: false,
    latest: false,
    library_id: '',
    related_videos: [],
    title: '',
    unlock_required: 0,
    video_duration: 0,
    video_thumbnail: '',
    video_url: '',
    library_video_and_video_tag: [],
    reward_chip: 0,
    difficulty: 1,
  });
  const update_edit_library = () => {
    set_loading_library(true);
    libraryService.registerNewOrUpdateVideoLibrary({
      id: edit_library.id,
      name: edit_library.name,
      type: edit_library.type,
      unlock_required: edit_library.unlock_required,
      library_author_id_1: edit_library.library_author_id_1 ?? '',
      library_author_id_2: edit_library.library_author_id_2 ?? '',
      library_author_id_3: edit_library.library_author_id_3 ?? '',
      library_author_id_4: edit_library.library_author_id_3 ?? '',
      library_author_id_5: edit_library.library_author_id_3 ?? '',
    }).then((_updated_library) => {
      if (_updated_library.type === LibraryType.webinar) {
        bus.emit('refetch-webinars')
      } else if (_updated_library.type === LibraryType.lesson) {
        bus.emit('refetch-lessons')
      } else if (_updated_library.type === LibraryType.online_session) {
        bus.emit('refetch-online-sessions')
      } else if (_updated_library.type === LibraryType.quiz) {
        bus.emit('refetch-quizzes')
      }
      toast.success(`Library (${_updated_library.id}) is updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_library(false);
    });
  }
  const delete_library = (library_id: string) => {
    libraryService.removeLibrary(library_id).then((_removed_library) => {
      if (_removed_library.type === LibraryType.webinar) {
        bus.emit('refetch-webinars')
      } else if (_removed_library.type === LibraryType.lesson) {
        bus.emit('refetch-lessons')
      } else if (_removed_library.type === LibraryType.online_session) {
        bus.emit('refetch-online-sessions')
      } else if (_removed_library.type === LibraryType.quiz) {
        bus.emit('refetch-quizzes')
      }
      toast.success(`Library (${_removed_library.id}) is deleted`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
  const register_new_library_video = () => {
    set_loading_library_video(true);
    libraryService.registerNewOrUpdateVideo(props.library.id, {
      id: new_library_video.id,
      title: new_library_video.title,
      description: new_library_video.description,
      unlock_required: new_library_video.unlock_required,
      video_duration: new_library_video.video_duration,
      video_thumbnail: new_library_video.video_thumbnail,
      video_tag_ids: new_library_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id),
      video_url: new_library_video.video_url,
      featured: new_library_video.featured,
      latest: new_library_video.latest,
      date: new_library_video.date,
      related_videos: new_library_video.related_videos,
      library_id: props.library.id,
      reward_chip: new_library_video.reward_chip,
      difficulty: new_library_video.difficulty,
    }).then(() => {
      if (props.library.type === LibraryType.webinar) {
        bus.emit('refetch-webinars');
      } else if (props.library.type === LibraryType.lesson) {
        bus.emit('refetch-lessons');
      } else if (props.library.type === LibraryType.online_session) {
        bus.emit('refetch-online-sessions');
      } else if (props.library.type === LibraryType.quiz) {
        bus.emit('refetch-quizzes');
      }
      toast.success(`New library is registered`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_library_video(false);
      set_new_library_video({
        id: '',
        library_id: '',
        related_videos: [],
        title: '',
        description: '',
        unlock_required: 0,
        video_duration: 0,
        video_thumbnail: '',
        video_url: '',
        featured: false,
        latest: false,
        date: '',
        reward_chip: 0,
        difficulty: 1,
        library_video_and_video_tag: []
      });
    });
  }
  const update_edit_library_video = () => {
    set_loading_library_video(true);
    libraryService.registerNewOrUpdateVideo(edit_library_video.library_id, {
      id: edit_library_video.id,
      library_id: props.library.id,
      title: edit_library_video.title,
      description: edit_library_video.description,
      unlock_required: edit_library_video.unlock_required,
      video_duration: edit_library_video.video_duration,
      video_thumbnail: edit_library_video.video_thumbnail,
      video_tag_ids: edit_library_video.library_video_and_video_tag.map(({ video_tag_id }) => video_tag_id),
      video_url: edit_library_video.video_url,
      featured: edit_library_video.featured,
      latest: edit_library_video.latest,
      date: edit_library_video.date,
      reward_chip: edit_library_video.reward_chip,
      related_videos: edit_library_video.related_videos,
      difficulty: edit_library_video.difficulty,
    }).then(() => {
      if (props.library.type === LibraryType.webinar) {
        bus.emit('refetch-webinars');
      } else if (props.library.type === LibraryType.lesson) {
        bus.emit('refetch-lessons');
      } else if (props.library.type === LibraryType.online_session) {
        bus.emit('refetch-online-sessions');
      } else if (props.library.type === LibraryType.quiz) {
        bus.emit('refetch-quizzes');
      }
      toast.success(`Library video (${edit_library_video.id}) is updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_library_video(false);
    });
  }
  const delete_library_video = (id: string) => {
    const yes = confirm(`Do you want to delete library video ${id}?`);
    if (yes) {
      libraryService.removeVideo(id).then((_deleted_library_video) => {
        if (props.library.type === LibraryType.webinar) {
          bus.emit('refetch-webinars');
        } else if (props.library.type === LibraryType.lesson) {
          bus.emit('refetch-lessons');
        } else if (props.library.type === LibraryType.online_session) {
          bus.emit('refetch-online-sessions');
        } else if (props.library.type === LibraryType.quiz) {
          bus.emit('refetch-quizzes');
        }
        toast.success(`Library video (${_deleted_library_video.id}) is deleted`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
    }
  }
  return (
    <>
      <div className="w-full flex items-start gap-4 border-b px-4 py-2">
        <div className="grow shrink">
          {props.library.name}
        </div>
        <div className="w-10 grow-0 shrink-0" title="Unlock required">
          {props.library.unlock_required}
        </div>
        <div className="w-20 grow-0 shrink-0" title="Authors">
          {[props.library.library_author_id_1, props.library.library_author_id_2, props.library.library_author_id_3, props.library.library_author_id_4, props.library.library_author_id_5].filter((author) => !!author).join(', ')}
        </div>
        <div className="w-20 grow-0 shrink-0 flex items-center gap-2">
          <button onClick={() => {
            set_edit_library(props.library);
            set_open_edit_library(true);
          }}>Edit</button>
          <button className="w-6 h-6 flex justify-center items-center border cursor-pointer" onClick={() => set_opened((_prev) => !_prev)}>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: opened ? 'rotate(0deg)' : 'rotate(180deg)' }}>
              <path d="M2 6L7 1L12 6" stroke={"#FFFFFF"} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      {opened && (
        <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 px-4 py-2">
          {props.library.library_videos.sort((a, b) => a.date < b.date ? 1 : -1).map((library_video, id) => (
            <CardVideo
              key={id}
              size="md"
              length={library_video.video_duration}
              tags={[]}
              title={library_video.title}
              videoThumbnail={library_video.video_thumbnail ?? "https://api.api-ninjas.com/v1/randomimage"}
              watched={false}
              on_change_watched={() => { }}
              on_click={() => {
                libraryService.getLibraryVideoWithTag(library_video.id).then((_library_video_with_tags) => {
                  const tmp: LibraryVideoAndVideoTag[] = _library_video_with_tags.library_video_and_video_tag
                  const res = tmp.reduce((a, b) => {
                    if (a.map(({ video_tag_id }) => video_tag_id).includes(b.video_tag_id)) {
                      return a
                    } else {
                      return [...a, b]
                    }
                  }, [] as LibraryVideoAndVideoTag[]);
                  _library_video_with_tags.library_video_and_video_tag = res;
                  set_edit_library_video(_library_video_with_tags);
                  set_open_edit_library_video(true);
                }).catch((err_msg: string) => {
                  alert(err_msg);
                })
              }}
            />
          ))}
          <button className="border border-primary-dark-grey rounded-2xl flex justify-center items-center" onClick={() => { set_open_new_library_video(true) }}>Add</button>
        </div>
      )}
      <Modal open={open_new_library_video} onClose={() => set_open_new_library_video(false)} center>
        <h2>Register new library video</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Title" title="Required title of video">Title(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Video Title" value={new_library_video.title} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Description" title="Required description of video">Description(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Video Description" value={new_library_video.description} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Thumbnail" title="Required thumbnail of video">Thumbnail</label>
          <input type="text" className="grow shrink border p-2" id="Video Thumbnail" value={new_library_video.video_thumbnail} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, video_thumbnail: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video URL" title="Required URL of video">URL</label>
          <input type="text" className="grow shrink border p-2" id="Video URL" value={new_library_video.video_url} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, video_url: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video URL" title="Required URL of video">Duration</label>
          <input type="number" className="grow shrink border p-2" id="Video URL" value={new_library_video.video_duration} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, video_duration: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Unlock" title="Required Unlock of video">Unlock Require</label>
          <input type="number" className="grow shrink border p-2" id="Video Unlock" value={new_library_video.unlock_required} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, unlock_required: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Reward Chip" title="Required Reward Chip of video">Reward Chip</label>
          <input type="number" className="grow shrink border p-2" id="Video Reward Chip" value={new_library_video.reward_chip} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, reward_chip: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Difficulty" title="Required Difficulty of video">Difficulty(0: Easy, 1: Medium, 2: Hard)</label>
          <input type="number" className="grow shrink border p-2" id="Video Difficulty" value={new_library_video.difficulty} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, difficulty: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Featured&Latest" title="Required Featured of video">Dashboard</label>
          <input id="Video Featured" type="checkbox" className="" checked={new_library_video.featured} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, featured: e.target.checked }))} />
          <label htmlFor="Video Featured">Featured</label>
          <input id="Video Latest" type="checkbox" className="" checked={new_library_video.latest} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, latest: e.target.checked }))} />
          <label htmlFor="Video Latest">Latest</label>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Related Videos" title="Related Videos of video">Related Videos</label>
          <textarea className="grow shrink border p-2" id="Related Videos" placeholder="video titles" value={new_library_video.related_videos.join(',')} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, related_videos: e.target.value.split(',') }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Description" title="Required description of video">Date</label>
          <input type="text" className="grow shrink border p-2" id="Video Description" value={new_library_video.date} onChange={(e) => set_new_library_video((_prev) => ({ ..._prev, date: e.target.value }))} />
        </div>
        <div className="w-full flex items-center gap-2 mt-2">
          <label className="w-32 text-black grow-0 shrink-0" htmlFor="Video Tags" title="Required description of video">Tags</label>
          <div className="flex flex-wrap gap-2">
            {(video_tags ?? []).map((video_tag, id) => (
              <div
                key={id}
                className={classNames(
                  "border px-2 py-1 cursor-pointer",
                  {
                    "text-white bg-black": new_library_video.library_video_and_video_tag.map((tag) => tag.video_tag_id).includes(video_tag.id)
                  }
                )}
                onClick={() => set_new_library_video((_prev) => ({
                  ..._prev,
                  library_video_and_video_tag: new_library_video.library_video_and_video_tag.map((tag) => tag.video_tag_id).includes(video_tag.id) ?
                    _prev.library_video_and_video_tag.filter(({ video_tag_id }) => video_tag_id !== video_tag.id) :
                    [..._prev.library_video_and_video_tag, { library_video_id: new_library_video.id, video_tag_id: video_tag.id, id: '' }]
                }))}
              >{video_tag.name}</div>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_library_video(false)}>Close</button>
          <div className="flex items-center gap-4">
            <button className="px-3 py-1 bg-green text-white" disabled={loading_library_video} onClick={register_new_library_video}>{loading_library_video ? 'Registering' : 'Register'}</button>
          </div>
        </div>
      </Modal>
      <Modal open={open_edit_library_video} onClose={() => set_open_edit_library_video(false)} center>
        <h2>Update library video {edit_library_video.id}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Title" title="Required title of video">Title(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Video Title" value={edit_library_video.title} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Description" title="Required description of video">Description(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Video Description" value={edit_library_video.description} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, description: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Thumbnail" title="Required thumbnail of video">Thumbnail</label>
          <input type="text" className="grow shrink border p-2" id="Video Thumbnail" value={edit_library_video.video_thumbnail} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, video_thumbnail: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video URL" title="Required URL of video">URL</label>
          <input type="text" className="grow shrink border p-2" id="Video URL" value={edit_library_video.video_url} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, video_url: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video URL" title="Required URL of video">Duration</label>
          <input type="number" className="grow shrink border p-2" id="Video URL" value={edit_library_video.video_duration} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, video_duration: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Unlock" title="Required Unlock of video">Unlock Require</label>
          <input type="number" className="grow shrink border p-2" id="Video Unlock" value={edit_library_video.unlock_required} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, unlock_required: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Reward Chip" title="Required reward chip of video">Reward Chip</label>
          <input type="number" className="grow shrink border p-2" id="Video Reward Chip" value={edit_library_video.reward_chip} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, reward_chip: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Difficulty" title="Required Difficulty of video">Difficulty(0: Easy, 1: Medium, 2: Difficult)</label>
          <input type="number" className="grow shrink border p-2" id="Video Difficulty" min={0} max={2} value={edit_library_video.difficulty} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, difficulty: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Featured&Latest" title="Required Featured of video">Dashboard</label>
          <input id="Video Featured" type="checkbox" className="" checked={edit_library_video.featured} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, featured: e.target.checked }))} />
          <label htmlFor="Video Featured">Featured</label>
          <input id="Video Latest" type="checkbox" className="" checked={edit_library_video.latest} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, latest: e.target.checked }))} />
          <label htmlFor="Video Latest">Latest</label>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Related Videos" title="Related Videos of video">Related Videos</label>
          <textarea className="grow shrink border p-2" id="Related Videos" placeholder="video titles" value={edit_library_video.related_videos.join(',')} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, related_videos: e.target.value.split(',') }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Video Description" title="Required description of video">Date</label>
          <input type="text" className="grow shrink border p-2" id="Video Description" value={edit_library_video.date} onChange={(e) => set_edit_library_video((_prev) => ({ ..._prev, date: e.target.value }))} />
        </div>
        <div className="w-full flex items-center gap-2 mt-2">
          <label className="w-32 text-black grow-0 shrink-0" htmlFor="Video Tags" title="Required description of video">Tags</label>
          <div className="flex flex-wrap gap-2">
            {(video_tags ?? []).map((video_tag, id) => (
              <div
                key={id}
                className={classNames(
                  "border px-2 py-1 cursor-pointer",
                  {
                    "text-white bg-black": edit_library_video.library_video_and_video_tag.map((tag) => tag.video_tag_id).includes(video_tag.id)
                  }
                )}
                onClick={() => set_edit_library_video((_prev) => ({
                  ..._prev,
                  library_video_and_video_tag: edit_library_video.library_video_and_video_tag.map((tag) => tag.video_tag_id).includes(video_tag.id) ?
                    _prev.library_video_and_video_tag.filter(({ video_tag_id }) => video_tag_id !== video_tag.id) :
                    [..._prev.library_video_and_video_tag, { library_video_id: edit_library_video.id, video_tag_id: video_tag.id, id: '' }]
                }))}
              >{video_tag.name}</div>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_library_video(false)}>Close</button>
          <div className="flex items-center gap-4">
            <button className="px-3 py-1 bg-red-500 text-white" onClick={() => delete_library_video(edit_library_video.id)}>Delete</button>
            <button className="px-3 py-1 bg-green text-white" disabled={loading_library_video} onClick={update_edit_library_video}>{loading_library_video ? 'Updating' : 'Update'}</button>
          </div>
        </div>
      </Modal>
      <Modal open={open_edit_library} onClose={() => set_open_edit_library(false)} center>
        <h2>Update library {edit_library.id}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Library Name" title="Required name of library">Name(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Library Name" value={edit_library.name} onChange={(e) => set_edit_library((_prev) => ({ ..._prev, name: e.target.value }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Library Unlock Required" title="Required Unlock Required of library">Unlock Required(*)</label>
          <input type="number" className="grow shrink border p-2" id="Library Unlock Required" value={edit_library.unlock_required} onChange={(e) => set_edit_library((_prev) => ({ ..._prev, unlock_required: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Library Type" title="Required Type of library">Type(*)</label>
          <select className="grow shrink border p-2" id="Library Type" value={edit_library.type} onChange={(e) => set_edit_library((_prev) => ({ ..._prev, type: e.target.value as LibraryType }))}>
            <option value={LibraryType.webinar}>{LibraryType.webinar}</option>
            <option value={LibraryType.lesson}>{LibraryType.lesson}</option>
            <option value={LibraryType.online_session}>{LibraryType.online_session}</option>
          </select>
        </div>
        <div className="flex items-start gap-2 mt-2">
          <label className="w-32 text-black" htmlFor="Library Author" title="Required Author of library">Author(*)</label>
          <select multiple className="grow shrink border p-2" id="Library Author" onChange={(e) => {
            const author_ids = Object.values(e.target.selectedOptions).map((option) => option.value);
            set_edit_library((_prev) => ({
              ..._prev,
              library_author_id_1: author_ids.length > 0 ? author_ids[0] : null,
              library_author_id_2: author_ids.length > 1 ? author_ids[1] : null,
              library_author_id_3: author_ids.length > 2 ? author_ids[2] : null,
              library_author_id_4: author_ids.length > 3 ? author_ids[3] : null,
              library_author_id_5: author_ids.length > 4 ? author_ids[4] : null,
            }))
          }}>
            {(library_authors ?? []).map((author, id) => (
              <option key={id} selected={[edit_library.library_author_id_1, edit_library.library_author_id_2, edit_library.library_author_id_3, edit_library.library_author_id_4, edit_library.library_author_id_5].includes(author.id)} value={author.id}>{author.id}. {author.first_name} {author.last_name}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_library(false)}>Close</button>
          <div className="flex items-center gap-4">
            <button className="px-3 py-1 bg-red-500 text-white" onClick={() => delete_library(edit_library.id)}>Delete</button>
            <button className="px-3 py-1 bg-green text-white" disabled={loading_library} onClick={update_edit_library}>{loading_library ? 'Updating' : 'Update'}</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const LibraryPanel = (props: { libraries: ILibraryWithVideos[] }) => {
  return (
    <div className="w-full flex flex-col text-white">
      {props.libraries.map((library, id) => (
        <Row library={library} key={id} />
      ))}
    </div>
  )
}