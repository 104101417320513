import axios, { type AxiosError } from 'axios';
import { CLCAvatars, LatestArticles, PrizeSliceText, ServerVars, User } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from '../config';
import { UTMConfigs } from '@clc-v2/utilities';

class DataService {
    async uploadPrizeTextsCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/prize-texts-upload`, formData).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getPrizeTexts() {
        return new Promise<PrizeSliceText[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/prize-texts`).then((res: { data: PrizeSliceText[] }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async addPrizeText(prize_text: Omit<PrizeSliceText, 'id'>) {
        return new Promise<PrizeSliceText>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/prize-texts`, prize_text).then((res: { data: PrizeSliceText }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updatePrizeText(prize_text: PrizeSliceText) {
        return new Promise<PrizeSliceText>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/prize-texts/${prize_text.id}`, prize_text).then((res: { data: PrizeSliceText }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removePrizeText(id: string) {
        return new Promise<PrizeSliceText>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/prize-texts/${id}`).then((res: { data: PrizeSliceText }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getLatestArticles() {
        return new Promise<LatestArticles[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/latest-articles`).then((res: { data: LatestArticles[] }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async addLatestArticles(new_article: Omit<LatestArticles, 'id'>) {
        return new Promise<LatestArticles>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/latest-articles`, new_article).then((res: { data: LatestArticles }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateLatestArticle(edit_article: LatestArticles) {
        return new Promise<LatestArticles>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/latest-articles/${edit_article.id}`, edit_article).then((res: { data: LatestArticles }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async deleteLatestArticle(id: string) {
        return new Promise<LatestArticles>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/latest-articles?id=${id}`).then((res: { data: LatestArticles }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getEnabledVerificationEmail() {
        return new Promise<boolean>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/server-vars/enabled-verification-email`).then((res: { data: { enabled: boolean } }) => {
                resolve(res.data.enabled)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async updateEnabledVerificationEmail(enable: boolean) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/server-vars/enabled-verification-email`, { enable }).then((res: { data: { enabled: boolean } }) => {
                resolve(res.data.enabled)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async getCountDailyQuestions() {
        return new Promise<number>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/server-vars/count-daily-questions`).then((res: { data: { count: number } }) => {
                resolve(res.data.count)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async updateCountDailyQuestions(count_daily_questions: number) {
        return new Promise<number>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/server-vars/count-daily-questions`, {
                count: count_daily_questions
            }).then((res: { data: { count: number } }) => {
                resolve(res.data.count)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async getCLCAvatars() {
        return new Promise<CLCAvatars[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/image-processor/clc-avatars`).then((res: { data: CLCAvatars[] }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async addCLCAvatar(clc_avatar: { name: string | null; path: string; thumbnail_path: string; }) {
        return new Promise<CLCAvatars>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/image-processor/clc-avatar`, clc_avatar).then((res: { data: CLCAvatars }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async deleteCLCAvatar(id: string) {
        return new Promise<CLCAvatars>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/image-processor/clc-avatar?id=${id}`).then((res: { data: CLCAvatars }) => {
                resolve(res.data)
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            })
        })
    }
    async getCountIntroVideo(from: string, to: string) {
        return new Promise<Array<{ month: string; count: number }>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/server-vars/count-tutorial-video?from=${from}&to=${to}`).then((res: { data: Array<{ month: string; count: number }> }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async getCountDiscordTutorialVideo(from: string, to: string) {
        return new Promise<Array<{ month: string; count: number }>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/server-vars/count-discord-tutorial-video?from=${from}&to=${to}`).then((res: { data: Array<{ month: string; count: number }> }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async getUtmConfigs() {
        return new Promise<UTMConfigs>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/server-vars/utm-configs`).then((res: { data: UTMConfigs }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async updateUtmConfigs(utm_configs: UTMConfigs) {
        return new Promise<UTMConfigs>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/server-vars/utm-configs`, utm_configs).then((res: { data: UTMConfigs }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
}

export const dataService = new DataService();
