import { ChangeEvent, useState } from "react";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { Glossary } from "@prisma/client";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { set_glossaries } from "../../stores/topic-lesson-question";
import { useBus } from "react-bus";
import Modal from "react-responsive-modal";
import lodash from "lodash";
import { toast } from "react-toastify";

export const GlossariesPanel = () => {
  const bus = useBus();
  const dispatch = useAppDispatch();
  const { glossaries } = useAppSelector((state) => state.topicLessonQuestion);
  const [loading_glossary, set_loading_glossary] = useState<boolean>(false);
  const [search_word, set_search_word] = useState<string>('');
  const [open_edit_glossary, set_open_edit_glossary] = useState<boolean>(false);
  const [edit_glossary, set_edit_glossary] = useState<Omit<Glossary, "created_at" | "updated_at">>({
    id: "",
    word: "",
    definition: "",
  });
  const [open_new_glossary, set_open_new_glossary] = useState<boolean>(false);
  const [new_glossary, set_new_glossary] = useState<Omit<Glossary, "created_at" | "updated_at">>({
    id: "",
    word: "",
    definition: "",
  });
  const update_edit_glossary = () => {
    set_loading_glossary(true);
    topicLessonQuestionService.updateEditGlossary({
      id: edit_glossary.id,
      word: edit_glossary.word,
      definition: edit_glossary.definition,
    }).then((_glossary) => {
      dispatch(
        set_glossaries((glossaries ?? []).map((_prev_glossary) => _prev_glossary.id === _glossary.id ? _glossary : _prev_glossary))
      )
      toast.warn(`Glossary (${_glossary.id}) is updated`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_glossary(false);
    })
  }
  const register_new_glossary = () => {
    set_loading_glossary(true);
    topicLessonQuestionService.registerNewGlossary({
      word: new_glossary.word,
      definition: new_glossary.definition,
    }).then((_glossary) => {
      dispatch(
        set_glossaries([...(glossaries ?? []), _glossary])
      )
      toast.warn(`Glossary (${_glossary.id}) is registered`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_glossary(false);
    })
  }
  const delete_glossary = (glossary_id: string) => {
    const yes = confirm(`Do you want to delete glossary ${glossary_id}?`);
    if (yes) {
      set_loading_glossary(true);
      topicLessonQuestionService.removeGlossary(glossary_id).then((_removed_glossary) => {
        dispatch(
          set_glossaries((glossaries ?? []).filter((glossary) => glossary.id !== _removed_glossary.id))
        )
        toast.warn(`Glossary (${_removed_glossary.id}) is deleted`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  const glossaries_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_glossary(true);
      topicLessonQuestionService.uploadGlossariesCSV(file).then((success) => {
        bus.emit('refetch-glossaries');
        toast.warn(`Glossary csv file is uploaded successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_glossary(false);
      });
    }
  }
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center gap-6 px-4 py-2 text-white mt-4">
        <div>
          <input className="text-black" type="text" placeholder="Search Word" onChange={lodash.debounce((e) => {
            set_search_word(e.target.value);
          }, 500)} />
        </div>
        <div className="flex items-center gap-6">
          <button className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1" onClick={() => set_open_new_glossary(true)}>Register</button>
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-csv-file-upload"
            title="Upload CSV Data from google sheet"
          >
            Upload CSV
          </label>
          <input id="topics-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={glossaries_csv_upload_handler} />
        </div>
      </div>
      {(glossaries ?? []).filter((glossary) => glossary.word.includes(search_word) || glossary.definition.includes(search_word)).map((glossary, id) => (
        <div className="w-full flex items-start gap-4 text-white border-b py-4 px-4" key={id}>
          <div className="w-40 grow-0 shrink-0">
            {glossary.id}
          </div>
          <div className="w-52 grow-0 shrink-0">
            {glossary.word}
          </div>
          <div className="grow shrink">
            {glossary.definition}
          </div>
          <div className="w-56 grow-0 shrink-0">
            {glossary.updated_at.toString()} (UTC)
          </div>
          <div className="flex items-center gap-6 grow-0 shrink-0">
            <button
              onClick={() => {
                set_edit_glossary({
                  id: glossary.id,
                  word: glossary.word,
                  definition: glossary.definition,
                });
                set_open_edit_glossary(true);
              }}
            >Edit</button>
            <button
              onClick={() => {
                delete_glossary(glossary.id);
              }}
            >Delete</button>
          </div>
        </div>
      ))}
      <Modal open={open_edit_glossary} onClose={() => set_open_edit_glossary(false)}>
        <h2>Update Glossary {edit_glossary.id}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Glossary Word" title="Required glossary word of question">Glossary Word(*)</label>
          <input className="grow shrink border p-2" type="text" id="Edit Glossary Word" value={edit_glossary.word} onChange={(e) => set_edit_glossary((_prev) => ({ ..._prev, word: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Glossary Description" title="Required glossary description of question">Glossary Description(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="Edit Glossary Description" value={edit_glossary.definition} onChange={(e) => set_edit_glossary((_prev) => ({ ..._prev, definition: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_glossary(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_glossary} onClick={update_edit_glossary}>{loading_glossary ? 'Updating' : 'Update'}</button>
        </div>
      </Modal>
      <Modal open={open_new_glossary} onClose={() => set_open_new_glossary(false)}>
        <h2>Register New Glossary</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Glossary Word" title="Required glossary word of question">Glossary Word(*)</label>
          <input className="grow shrink border p-2" type="text" id="New Glossary Word" value={new_glossary.word} onChange={(e) => set_new_glossary((_prev) => ({ ..._prev, word: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Glossary Description" title="Required glossary description of question">Glossary Description(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="New Glossary Description" value={new_glossary.definition} onChange={(e) => set_new_glossary((_prev) => ({ ..._prev, definition: e.target.value }))} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_glossary(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_glossary} onClick={register_new_glossary}>{loading_glossary ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
    </div>
  )
}