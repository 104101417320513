import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1'
import { AuthState, authReducer } from './auth'
import { TopicLessonQuestionState, topicLessonQuestionReducer } from './topic-lesson-question'
import { LibraryState, libraryReducer } from './library'

export const STORE_KEY = 'clc-v2-manage-store';

const persistConfig = {
    key: STORE_KEY,
    storage,
    stateReconciler: autoMergeLevel1,
    // whitelist: ["state_which_you_want_to_persist"],
    blacklist: ["temp"]
}

export interface GlobalState {
    auth: AuthState;
    topicLessonQuestion: TopicLessonQuestionState;
    library: LibraryState;
}

const reducers = combineReducers({
    auth: authReducer,
    topicLessonQuestion: topicLessonQuestionReducer,
    library: libraryReducer,
});
const persistedReducer = persistReducer<GlobalState>(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
