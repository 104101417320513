import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";

class ImageService {
  async getSignedURL(path: string, name: string, type: string) {
    return new Promise<{
      signed_url: string;
      path: string;
    }>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/image-processor/signed-url?file_path=${path}&file_name=${name}&file_type=${type}`).then((res: {
        data: {
          signed_url: string;
          path: string;
        }
      }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
  async uploadFileToGCS(signed_url: string, file: File) {
    return new Promise<void>((resolve, reject) => {
      axios.put(signed_url, file, {
        headers: {
          'Content-Type': file.type,
        },
      }).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      })
    })
  }
  async viewGCSStatus() {
    return new Promise<Object>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/image-processor/view-gcs-status`).then((res: { data: Object }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
  async updateGCSCors(cors_domains: string[]) {
    return new Promise<Object>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/image-processor/update-gcs-cors`, {
        domains: cors_domains
      }).then((res: { data: Object }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
}

export const imageService = new ImageService();
