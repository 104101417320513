import { Button } from "@clc-v2/uis"
import { Level } from "@prisma/client"
import { useEffect, useState } from "react";
import { pointSystemService } from "../services/point-system.service";
import Modal from "react-responsive-modal";
import { IcEdit, IcTrash, IcUpload } from "@clc-v2/icons";
import { ANSWER_DAILY_20_QUESTIONS, ANSWER_WEEKLY_NEW_HANDS, DISCORD_JOINT, INVITE_REWARD, INVITE_SUBSCRIPTION_ACTIVE, LOGIN_DAY1, LOGIN_DAY2, LOGIN_DAY3, LOGIN_DAY4, LOGIN_DAY5, LOGIN_DAY6, LOGIN_DAY7, MASTER_LESSON, splitFileName } from "@clc-v2/utilities";
import { imageService } from "../services/image.service";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { topicLessonQuestionService } from "../services/topic-lesson-question.service";
import { set_topics } from "../stores/topic-lesson-question";

export const Reward = () => {
  const dispatch = useAppDispatch();
  const { topics } = useAppSelector((state) => state.topicLessonQuestion);
  const [reward_login_day1, set_reward_login_day1] = useState<number>(0);
  const [reward_login_day2, set_reward_login_day2] = useState<number>(0);
  const [reward_login_day3, set_reward_login_day3] = useState<number>(0);
  const [reward_login_day4, set_reward_login_day4] = useState<number>(0);
  const [reward_login_day5, set_reward_login_day5] = useState<number>(0);
  const [reward_login_day6, set_reward_login_day6] = useState<number>(0);
  const [reward_login_day7, set_reward_login_day7] = useState<number>(0);
  // const [reward_watch_video, set_reward_watch_video] = useState<number>(0);
  const [reward_daily_20_questions, set_reward_daily_20_questions] = useState<number>(0);
  const [reward_weekly_new_hands, set_reward_weekly_new_hands] = useState<number>(0);
  const [reward_discord_joint, set_reward_discord_joint] = useState<number>(0);
  const [reward_invite_user, set_reward_invite_user] = useState<number>(0);
  const [reward_invite_subscription_active, set_reward_invite_subscription_active] = useState<number>(0);
  const [reward_master_lesson, set_reward_master_lesson] = useState<number>(0);
  const [levels, set_levels] = useState<Level[]>([]);
  const [last_level, set_last_level] = useState<Omit<Level, 'id'>>();
  const [open_new_level_modal, set_open_new_level_modal] = useState<boolean>(false);
  const [new_level, set_new_level] = useState<Level>({
    id: '',
    title: '',
    badge: '',
    init_point: 0,
    available_topic_ids: []
  });
  const [new_level_title_err, set_new_level_title_err] = useState<string>('');
  const [new_level_badge_err, set_new_level_badge_err] = useState<string>('');
  const [new_level_init_point_err, set_new_level_init_point_err] = useState<string>('');
  const [new_level_available_topics_err, set_new_level_available_topics_err] = useState<string>('');
  const [new_level_badge_file, set_new_level_badge_file] = useState<File>();
  const [new_level_badge_file_64_64, set_new_level_badge_file_64_64] = useState<File>();
  const [edit_level_id, set_edit_level_id] = useState<string>('');
  const [loading_level, set_loading_level] = useState<boolean>(false);
  const update_reward = (type: string, reward: number) => {
    pointSystemService.updateRewardByPointType(type, reward).then((data) => {
      console.log(data)
    }).catch((err_msg: string) => {
      alert(err_msg);
    })
  }
  const _add_new_level = (data: Omit<Level, 'id'>) => {
    pointSystemService.addLevel(data).then((_level) => {
      set_levels((_prev) => [..._prev, _level].sort((a, b) => a.init_point - b.init_point))
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_loading_level(false);
    })
  }
  const _update_edit_level = (data: Level) => {
    pointSystemService.updateLevel(data).then((_level) => {
      set_levels((_prev) => _prev.map((_el) => _el.id === _level.id ? _level : _el).sort((a, b) => a.init_point - b.init_point))
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_loading_level(false);
    })
  }
  const add_new_or_update_edit_level = (callback: (data: Level) => void) => {
    if (new_level.title === '') {
      return set_new_level_title_err('Title is required')
    } else {
      set_new_level_title_err('')
    }
    // if (new_level.badge === '') {
    //   return set_new_level_badge_err('Badge is required')
    // } else {
    //   set_new_level_badge_err('')
    // }
    if (new_level.init_point > 0) {
      set_new_level_init_point_err('')
    } else {
      return set_new_level_init_point_err('Init point should be higher than previous point')
    }
    if (new_level.available_topic_ids.length > 0) {
      set_new_level_available_topics_err('')
    } else {
      return set_new_level_available_topics_err('Should select available topics')
    }
    set_loading_level(true);
    if (new_level_badge_file_64_64) {
      const timestamp = new Date().getTime();
      const { name, extension } = splitFileName(new_level_badge_file_64_64.name.trim());
      const file_name_64 = `${name}-${timestamp}_64_64` + (extension ? `.${extension}` : '');
      imageService.getSignedURL('level-badge', file_name_64, new_level_badge_file_64_64.type).then(({ signed_url: _signed_url, path }) => {
        imageService.uploadFileToGCS(_signed_url, new_level_badge_file_64_64).then(() => {
          callback({
            id: new_level.id,
            title: new_level.title,
            badge: path,
            init_point: new_level.init_point,
            available_topic_ids: new_level.available_topic_ids
          })
        }).catch((err) => {
          alert(err)
        }).finally(() => {
          set_loading_level(false);
        })
      }).catch((err) => {
        alert(err)
        set_loading_level(false);
      })
    } else {
      callback({
        id: new_level.id,
        title: new_level.title,
        badge: new_level.badge,
        init_point: new_level.init_point,
        available_topic_ids: new_level.available_topic_ids
      })
    }
  }
  const delete_level = (id: string) => {
    pointSystemService.deleteLevel(id).then((_deleted) => {
      set_levels((_prev) => _prev.filter((level) => level.id !== _deleted.id))
    }).catch((err_msg: string) => {
      alert(err_msg)
    })
  }
  useEffect(() => {
    pointSystemService.getRewardPoints().then((rewards) => {
      for (const reward of rewards) {
        if (reward.type === LOGIN_DAY1) {
          set_reward_login_day1(reward.reward);
        } else if (reward.type === LOGIN_DAY2) {
          set_reward_login_day2(reward.reward);
        } else if (reward.type === LOGIN_DAY3) {
          set_reward_login_day3(reward.reward);
        } else if (reward.type === LOGIN_DAY4) {
          set_reward_login_day4(reward.reward);
        } else if (reward.type === LOGIN_DAY5) {
          set_reward_login_day5(reward.reward);
        } else if (reward.type === LOGIN_DAY6) {
          set_reward_login_day6(reward.reward);
        } else if (reward.type === LOGIN_DAY7) {
          set_reward_login_day7(reward.reward);
          // } else if (reward.type === RewardPointType.WATCH_VIDEO) {
          // set_reward_watch_video(reward.reward);
        } else if (reward.type === ANSWER_DAILY_20_QUESTIONS) {
          set_reward_daily_20_questions(reward.reward);
        } else if (reward.type === ANSWER_WEEKLY_NEW_HANDS) {
          set_reward_weekly_new_hands(reward.reward);
        } else if (reward.type === DISCORD_JOINT) {
          set_reward_discord_joint(reward.reward);
        } else if (reward.type === INVITE_REWARD) {
          set_reward_invite_user(reward.reward);
        } else if (reward.type === INVITE_SUBSCRIPTION_ACTIVE) {
          set_reward_invite_subscription_active(reward.reward);
        } else if (reward.type === MASTER_LESSON) {
          set_reward_master_lesson(reward.reward);
        }
      }
    }).catch((err_msg: string) => {
      alert(err_msg);
    })
    pointSystemService.getLevels().then((_levels) => {
      set_levels(_levels);
      if (_levels.length > 0) {
        set_last_level(_levels.slice(-1)[0])
      } else {
        set_last_level(undefined)
      }
    }).catch((err_msg: string) => {
      alert(err_msg);
    })
  }, []);
  useEffect(() => {
    if (new_level_badge_file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 64;
          canvas.height = 64;
          ctx?.drawImage(img, 0, 0, 64, 64);
          canvas.toBlob((blob) => {
            if (blob) {
              const resizedImageFile = new File([blob], new_level_badge_file.name, { type: new_level_badge_file.type });
              set_new_level_badge_file_64_64(resizedImageFile);
            }
          }, new_level_badge_file.type);
          const resizedImageUrl = canvas.toDataURL(new_level_badge_file.type);
          set_new_level((_prev) => ({ ..._prev, badge: resizedImageUrl }));
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(new_level_badge_file);
    }
  }, [new_level_badge_file]);
  useEffect(() => {
    if (topics === undefined || topics.length === 0) {
      topicLessonQuestionService.getAllTopics().then((_topics) => {
        dispatch(
          set_topics(_topics)
        )
      }).catch((err_msg: string) => {
        console.error(err_msg);
      })
    }
  }, [])
  return (
    <div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day1">{LOGIN_DAY1}</label>
        <input id="reward-login-day1" type="number" value={reward_login_day1} onChange={(e) => set_reward_login_day1(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY1, reward_login_day1)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day2">{LOGIN_DAY2}</label>
        <input id="reward-login-day2" type="number" value={reward_login_day2} onChange={(e) => set_reward_login_day2(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY2, reward_login_day2)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day3">{LOGIN_DAY3}</label>
        <input id="reward-login-day3" type="number" value={reward_login_day3} onChange={(e) => set_reward_login_day3(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY3, reward_login_day3)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day4">{LOGIN_DAY4}</label>
        <input id="reward-login-day4" type="number" value={reward_login_day4} onChange={(e) => set_reward_login_day4(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY4, reward_login_day4)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day5">{LOGIN_DAY5}</label>
        <input id="reward-login-day5" type="number" value={reward_login_day5} onChange={(e) => set_reward_login_day5(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY5, reward_login_day5)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day6">{LOGIN_DAY6}</label>
        <input id="reward-login-day6" type="number" value={reward_login_day6} onChange={(e) => set_reward_login_day6(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY6, reward_login_day6)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-login-day7">{LOGIN_DAY7}</label>
        <input id="reward-login-day7" type="number" value={reward_login_day7} onChange={(e) => set_reward_login_day7(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(LOGIN_DAY7, reward_login_day7)}>
          Update
        </Button>
      </div>
      {/* <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-watch-video">{RewardPointType.WATCH_VIDEO}</label>
        <input id="reward-watch-video" type="number" value={reward_watch_video} onChange={(e) => set_reward_watch_video(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(RewardPointType.WATCH_VIDEO, reward_watch_video)}>
          Update
        </Button>
      </div> */}
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-daily-20-questions">{ANSWER_DAILY_20_QUESTIONS}</label>
        <input id="reward-daily-20-questions" type="number" value={reward_daily_20_questions} onChange={(e) => set_reward_daily_20_questions(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(ANSWER_DAILY_20_QUESTIONS, reward_daily_20_questions)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-weekly-new-hands">{ANSWER_WEEKLY_NEW_HANDS}</label>
        <input id="reward-weekly-new-hands" type="number" value={reward_weekly_new_hands} onChange={(e) => set_reward_weekly_new_hands(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(ANSWER_WEEKLY_NEW_HANDS, reward_weekly_new_hands)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-discord-joint">{DISCORD_JOINT}</label>
        <input id="reward-discord-joint" type="number" value={reward_discord_joint} onChange={(e) => set_reward_discord_joint(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(DISCORD_JOINT, reward_discord_joint)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-invite-user">{INVITE_REWARD}</label>
        <input id="reward-invite-user" type="number" value={reward_invite_user} onChange={(e) => set_reward_invite_user(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(INVITE_REWARD, reward_invite_user)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-master-lesson">{INVITE_SUBSCRIPTION_ACTIVE}</label>
        <input id="reward-master-lesson" type="number" value={reward_invite_subscription_active} onChange={(e) => set_reward_invite_subscription_active(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(INVITE_SUBSCRIPTION_ACTIVE, reward_invite_subscription_active)}>
          Update
        </Button>
      </div>
      <div className="w-full mt-4 flex items-center gap-4">
        <label className="grow-0 shrink-0 w-80 text-white" htmlFor="reward-master-lesson">{MASTER_LESSON}</label>
        <input id="reward-master-lesson" type="number" value={reward_master_lesson} onChange={(e) => set_reward_master_lesson(parseInt(e.target.value))} />
        <Button className="text-white px-4 py-2" type="secondary" onClick={() => update_reward(MASTER_LESSON, reward_master_lesson)}>
          Update
        </Button>
      </div>
      <div className="w-full grid grid-cols-4 gap-2 mt-4">
        <h2 className="col-span-4 text-white">Levels</h2>
        {levels.length > 0 ? levels.map((level, id) => (
          <div className="col-span-4 w-full h-20 flex items-center gap-4 border text-white p-2 relative" key={id}>
            {level.badge ? <img className="w-16 h-16" src={level.badge} alt={level.title} /> : <div className="w-16 h-16 flex justify-center items-center"><span className="text-white">No Badge</span></div>}
            <div className="flex flex-col justify-center items-start gap-0">
              <span>{level.title}</span>
              <span>{level.init_point} ~ {id === (levels.length - 1) ? '' : levels[id + 1].init_point}</span>
              <span>{(level.available_topic_ids ?? []).join(' ')}</span>
            </div>
            <button className="absolute right-0 top-0 text-white" onClick={() => delete_level(level.id)}>
              <IcTrash color="white" />
            </button>
            <button className="absolute right-6 top-0 text-white" onClick={() => {
              // set_edit_level_id(level.id)
              set_open_new_level_modal(true)
              set_new_level(level)
            }}>
              <IcEdit color="white" />
            </button>
          </div>
        )) : (
          <div className="w-full h-20 flex flex-col justify-center items-center gap-2 text-white">
            Does not have registered levels
          </div>
        )}
        <button className="w-full h-20 flex flex-col justify-center items-center gap-2 border  text-white" onClick={() => {
          set_open_new_level_modal(true)
          // set_edit_level_id('')
          set_new_level({
            id: '',
            title: '',
            badge: '',
            init_point: 0,
            available_topic_ids: []
          })
        }}>ADD {levels.length === 0 ? '1' : 'Next'} Level</button>
      </div>
      <Modal open={open_new_level_modal} onClose={() => set_open_new_level_modal(false)} center>
        {new_level.title === '' ? (
          <h2 className="text-xl">Add <b>{levels.length + 1}</b> Level</h2>) : (
          <h2 className="text-xl">Edit <b>{new_level.title}</b> Level</h2>
        )}
        <hr className="w-full my-4" />
        <div className="flex items-center gap-4">
          <label className="grow-0 shrink-0 w-40 text-black" htmlFor="new-level-title">Title(*)</label>
          <input id="new-level-title" type="text" className="grow shrink px-4 py-2 border" value={new_level.title} placeholder={last_level?.title} onChange={(e) => set_new_level((_prev) => ({ ..._prev, title: e.target.value }))} />
        </div>
        {new_level_title_err && <p className="ml-44">{new_level_title_err}</p>}
        <div className="flex items-center gap-4 mt-2">
          <label className="grow-0 shrink-0 w-40 text-black" htmlFor="new-level-badge">Badge(Optional)</label>
          <input id="new-level-badge" type="text" className="grow shrink px-4 py-2 border" value={new_level.badge} onChange={(e) => set_new_level((_prev) => ({ ..._prev, badge: e.target.value }))} />
          <label htmlFor="pick-level-badge">
            Upload Image
          </label>
          <input id="pick-level-badge" type="file" hidden onChange={(e) => set_new_level_badge_file(e.target.files?.[0])} />
        </div>
        {new_level_badge_err && <p className="ml-44">{new_level_badge_err}</p>}
        {new_level.badge && <div className="flex mt-2">
          <img className="ml-44 w-16 h-16" src={new_level.badge} alt={new_level.title} />
        </div>}
        <div className="flex items-center gap-4 mt-2">
          <label className="grow-0 shrink-0 w-40 text-black" htmlFor="new-level-init-point">Init Point<br />{last_level ? `(${last_level.init_point} * 1.8 = ${Math.floor(last_level.init_point * 1.8)})` : ''}</label>
          <input id="new-level-init-point" type="number" className="grow shrink px-4 py-2 border" value={new_level.init_point} onChange={(e) => set_new_level((_prev) => ({ ..._prev, init_point: parseInt(e.target.value) }))} />
        </div>
        {new_level_init_point_err && <p className="ml-44">{new_level_init_point_err}</p>}
        <div className="flex items-center gap-4 mt-2">
          <label className="grow-0 shrink-0 w-40 text-black" htmlFor="new-level-topics">Topics</label>
          <select className="grow shrink px-4 py-2 border" multiple value={new_level.available_topic_ids} onChange={(e) => {
            const selectedTopicIds = Object.values(e.target.selectedOptions).map((option) => option.value);
            set_new_level((_prev) => ({ ..._prev, available_topic_ids: selectedTopicIds }))
          }}>
            {(topics ?? []).map((topic) => (
              <option selected={new_level.available_topic_ids.includes(topic.uid)} key={topic.uid} value={topic.uid}>{topic.name}</option>
            ))}
          </select>
        </div>
        {new_level_available_topics_err && <p className="ml-44">{new_level_available_topics_err}</p>}
        <div className="flex justify-between items-center mt-4">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_level_modal(false)}>Close</button>
          {new_level.id === '' ? (
            <button className="px-3 py-1 bg-green text-white" disabled={loading_level} onClick={() => add_new_or_update_edit_level(_add_new_level)}>{loading_level ? 'Adding' : 'Add'}</button>
          ) : (
            <button className="px-3 py-1 bg-green text-white" disabled={loading_level} onClick={() => add_new_or_update_edit_level(_update_edit_level)}>{loading_level ? 'Updating' : 'Update'}</button>
          )}
        </div>
      </Modal>
    </div>
  )
}