import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../stores/hooks"
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import { Topic } from "@prisma/client";
import Modal from "react-responsive-modal";
import { set_topics } from "../../stores/topic-lesson-question";
import { useBus } from "react-bus";
import lodash from "lodash";
import { toast } from 'react-toastify';

export const TopicsPanel = () => {
  const bus = useBus();
  const dispatch = useAppDispatch();
  const { topics } = useAppSelector((state) => state.topicLessonQuestion);
  const [loading_topics, set_loading_topics] = useState<boolean>(false);
  const [search_word, set_search_word] = useState<string>('');
  const [open_new_topic, set_open_new_topic] = useState<boolean>(false);
  const [new_topic, set_new_topic] = useState<Omit<Topic, "created_at" | "updated_at">>({
    uid: "",
    name: "",
    mastered_require: 0
  });
  const [open_edit_topic, set_open_edit_topic] = useState<boolean>(false);
  const [edit_topic, set_edit_topic] = useState<Omit<Topic, "created_at" | "updated_at">>({
    uid: "",
    name: "",
    mastered_require: 0,
  });
  const update_edit_topic = () => {
    set_loading_topics(true);
    topicLessonQuestionService.updateEditTopic(edit_topic).then((_topic) => {
      dispatch(
        set_topics((topics ?? []).map((prev_topic) => (prev_topic.uid === _topic.uid ? { ..._topic, lessons: [] } : prev_topic)))
      );
      toast.success(`Topic (${_topic.uid}) is updated.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_topics(false);
    });
  }
  const register_new_topic = () => {
    set_loading_topics(true);
    topicLessonQuestionService.registerNewTopic(new_topic).then((_topic) => {
      dispatch(
        set_topics([...(topics ?? []), _topic])
      );
      toast.success(`Topic (${_topic.uid}) is registered.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_topics(false);
    });
  }
  const delete_topic = (uid: string) => {
    const yes = confirm(`Do you want to delete topic ${uid}?`);
    if (yes) {
      set_loading_topics(true);
      topicLessonQuestionService.removeTopic(uid).then((_removed_topic) => {
        dispatch(
          set_topics((topics ?? []).filter((topic) => topic.uid !== _removed_topic.uid))
        )
        toast.warn(`Topic (${_removed_topic.uid}) is deleted.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_topics(false);
      });
    }
  }
  const topic_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_topics(true);
      topicLessonQuestionService.uploadTopicCSV(file).then((success) => {
        bus.emit('refetch-topics');
        toast.success('Topic csv file is uploaded successfully', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_topics(false);
      });
    }
  }
  const topic_backup_v1_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_topics(true);
      topicLessonQuestionService.uploadTopicBackupV1CSV(file).then((success) => {
        bus.emit('refetch-topics');
        toast.success(`Topic csv file(v1) is uploaded successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_topics(false);
      });
    }
  }
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center gap-6 px-4 py-2 text-white mt-4">
        <div>
          <input className="text-black" type="text" placeholder="Search Word" onChange={lodash.debounce((e) => {
            set_search_word(e.target.value);
          }, 500)} />
        </div>
        <div className="flex items-center gap-6">
          <button className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1" onClick={() => set_open_new_topic(true)}>Register</button>
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-csv-file-upload"
            title="Upload CSV Data from google sheet"
          >
            Upload CSV
          </label>
          <input id="topics-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={topic_csv_upload_handler} />
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-backup-v1-csv-file-upload"
            title="Upload CSV Data from v1 db backup"
          >
            Upload CSV V1
          </label>
          <input id="topics-backup-v1-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={topic_backup_v1_csv_upload_handler} />
        </div>
      </div>
      {(topics ?? []).filter((topic) => topic.name.toLowerCase().includes(search_word.toLowerCase()) || topic.uid.toLowerCase().includes(search_word.toLowerCase())).map((topic, id) => (
        <div className="w-full flex items-center gap-4 text-white border-b py-4 px-4" key={id}>
          <div className="w-10 grow-0 shrink-0">
            {topic.uid}
          </div>
          <div className="grow shrink">
            {topic.name}
          </div>
          <div className="w-10 grow-0 shrink-0">
            {topic.mastered_require}
          </div>
          <div className="w-96 grow-0 shrink-0">
            {topic.updated_at.toString()} (UTC)
          </div>
          <div className="w-32 flex items-center gap-6 grow-0 shrink-0">
            <button
              onClick={() => {
                set_edit_topic({
                  uid: topic.uid,
                  name: topic.name,
                  mastered_require: topic.mastered_require
                });
                set_open_edit_topic(true);
              }}
            >Edit</button>
            <button
              onClick={() => {
                delete_topic(topic.uid);
              }}
            >Delete</button>
          </div>
        </div>
      ))}
      <Modal open={open_edit_topic} onClose={() => set_open_edit_topic(false)}>
        <h2>Edit Topic of <b>{edit_topic.uid}</b></h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label
            className="w-40 text-black"
            htmlFor="Edit Topic Name"
            title="Required name of topic"
          >Topic Name(*)</label>
          <input
            className="grow shrink border p-2"
            type="text"
            id="Edit Topic Name"
            value={edit_topic.name}
            onChange={(e) => set_edit_topic((_prev) => ({ ..._prev, name: e.target.value }))}
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Topic Mastered Require" title="Required required chips of topic">
            Mastered Required(*)
          </label>
          <input
            className="grow shrink border p-2"
            type="number"
            id="Edit Topic Mastered Require"
            value={edit_topic.mastered_require}
            onChange={(e) => set_edit_topic((_prev) => ({ ..._prev, mastered_require: parseInt(e.target.value) }))}
          />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_topic(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_topics} onClick={update_edit_topic}>{loading_topics ? 'Updating' : 'Update'}</button>
        </div>
      </Modal>
      <Modal open={open_new_topic} onClose={() => set_open_new_topic(false)}>
        <h2>Register New Topic</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label
            className="w-40 text-black"
            htmlFor="New Topic UID"
            title="Required id of topic"
          >Topic UID(*)</label>
          <input
            className="grow shrink border p-2"
            type="text"
            id="New Topic UID"
            value={new_topic.uid}
            onChange={(e) => set_new_topic((_prev) => ({ ..._prev, uid: e.target.value }))}
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label
            className="w-40 text-black"
            htmlFor="New Topic Name"
            title="Required name of topic"
          >Topic Name(*)</label>
          <input
            className="grow shrink border p-2"
            type="text"
            id="New Topic Name"
            value={new_topic.name}
            onChange={(e) => set_new_topic((_prev) => ({ ..._prev, name: e.target.value }))}
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label
            className="w-40 text-black"
            htmlFor="New Topic Mastered Require"
            title="Required required chips of topic"
          >Mastered Required(*)</label>
          <input
            className="grow shrink border p-2"
            type="number"
            id="New Topic Mastered Require"
            value={new_topic.mastered_require}
            onChange={(e) => set_new_topic((_prev) => ({ ..._prev, mastered_require: parseInt(e.target.value) }))}
          />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button
            className="px-3 py-1 border text-black"
            onClick={() => set_open_new_topic(false)}
          >Close</button>
          <button
            className="px-3 py-1 bg-green text-white"
            disabled={loading_topics}
            onClick={register_new_topic}
          >{loading_topics ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
    </div>
  )
}