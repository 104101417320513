import React, { ReactNode } from 'react';
import { Provider } from 'react-redux'
import { persistor, store } from './stores'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as BusProvider } from 'react-bus'

interface ProviderProps {
  children: ReactNode;
}

export const Providers: React.FC<ProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BusProvider>
        {children}
        </BusProvider>
      </PersistGate>
    </Provider>
  );
};