import { Library, LibraryAuthor, LibraryVideo, LibraryVideoTag } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from "../config";
import axios, { AxiosError } from "axios";
import { ILibrary, ILibraryAuthor, ILibraryAuthorWithAuthorDetail, ILibraryWithVideos, INewLibrary, LibraryVideoWithTag } from "@clc-v2/utilities";

class LibraryService {
    async getAllVideoTags() {
        return new Promise<Array<LibraryVideoTag>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-tag`).then((res: { data: LibraryVideoTag[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async registerNewVideoTag(new_video_tag: string) {
        return new Promise<LibraryVideoTag>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/video-tag`, { name: new_video_tag }).then((res: { data: LibraryVideoTag }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async updateVideoTag(edit_video_tag: LibraryVideoTag) {
        return new Promise<LibraryVideoTag>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/library/video-tag/${edit_video_tag.id}`, edit_video_tag).then((res: { data: LibraryVideoTag }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async deleteVideoTag(video_tag_id: string) {
        return new Promise<LibraryVideoTag>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/library/video-tag/${video_tag_id}`).then((res: { data: LibraryVideoTag }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllAuthors() {
        return new Promise<Array<ILibraryAuthorWithAuthorDetail>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-author`).then((res: { data: ILibraryAuthorWithAuthorDetail[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async updateAuthor(user_id: string) {
        return new Promise<{ created: boolean, data: LibraryAuthor }>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-author/update`, { user_id }).then((res: {
                data: { created: boolean, data: LibraryAuthor }
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async uploadLibraryAuthorCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-author/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllWebinars() {
        return new Promise<ILibraryWithVideos[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-library/webinars`).then((res: { data: ILibraryWithVideos[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllLessons() {
        return new Promise<ILibraryWithVideos[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-library/lessons`).then((res: { data: ILibraryWithVideos[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllOnlineSessions() {
        return new Promise<ILibraryWithVideos[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-library/online-sessions`).then((res: { data: ILibraryWithVideos[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getAllQuizzes() {
        return new Promise<ILibraryWithVideos[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/video-library/quizzes`).then((res: { data: ILibraryWithVideos[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewOrUpdateAuthor(library_author: ILibraryAuthor) {
        return new Promise<LibraryAuthor>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-author`, library_author).then((res: { data: LibraryAuthor }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async removeAuthor(author_id: string) {
        return new Promise<LibraryAuthor>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/library/library-author?id=${author_id}`).then((res: { data: LibraryAuthor }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async registerNewOrUpdateVideoLibrary(library: ILibrary & { id: string }) {
        return new Promise<Library>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/video-library`, library).then((res: { data: Library }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeLibrary(library_id: string) {
        return new Promise<Library>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/library/video-library/${library_id}`).then((res: { data: Library }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadLibraryCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/library/video-library/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async registerNewOrUpdateVideo(library_id: string, new_video: LibraryVideo & { video_tag_ids: string[] }) {
        return new Promise<LibraryVideo>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-video?library_id=${library_id}`, new_video).then((res: { data: LibraryVideo }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async removeVideo(video_id: string) {
        return new Promise<LibraryVideo>((resolve, reject) => {
            axios.delete(`${API_BACKEND_ENDPOINT}/library/library-video?video_id=${video_id}`).then((res: { data: LibraryVideo }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getVideosOfLibrary(library_id: string) {
        return new Promise<LibraryVideoWithTag[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video?library_id=${library_id}`).then((res: { data: LibraryVideoWithTag[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async uploadLibraryVideoCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-video/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getLibraryVideoWithTag(id: string) {
        return new Promise<LibraryVideoWithTag>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/library/library-video/${id}`).then((res: { data: LibraryVideoWithTag }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updateLibraryVideoWatched(user_id: string, library_videos_titles: string[]) {
        return new Promise<{ count: number }>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/library/library-video/watch`, {
                user_id, library_videos_titles
            }).then((res: { data: { count: number } }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const libraryService = new LibraryService();
