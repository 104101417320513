import { useEffect, useState } from "react"
import { reportService } from "../services/report.service"
import { ReportUser } from "@prisma/client"
import Modal from "react-responsive-modal";
import moment from "moment";

export const Report = () => {
  const [loading_report_users, set_loading_report_users] = useState<boolean>(false);
  const [report_users, set_report_users] = useState<ReportUser[]>([]);
  const [open_custom_report, set_open_custom_report] = useState<boolean>(false);
  const [custom_report_email, set_custom_report_email] = useState<string>('');
  const [custom_report_from, set_custom_report_from] = useState<string>(moment().format('YYYY-MM-DD'));
  const [custom_report_to, set_custom_report_to] = useState<string>(moment().format('YYYY-MM-DD'));
  const [loading_custom_report, set_loading_custom_report] = useState<boolean>(false);
  const [open_add_new_email, set_open_add_new_email] = useState<boolean>(false);
  const [new_user, set_new_user] = useState<Omit<ReportUser, 'id'>>({
    email: '',
    report_type: '08:00',
    enable: true,
  });
  const [open_edit_user, set_open_edit_user] = useState<boolean>(false);
  const [edit_user, set_edit_user] = useState<ReportUser>({
    id: 0,
    email: '',
    report_type: '',
    enable: false,
  })
  const registerNewEmail = () => {
    if (new_user.email !== '') {
      set_loading_report_users(true);
      reportService.registerNewEmail(new_user).then((_new_user) => {
        set_report_users((_prev) => [..._prev, _new_user])
      }).catch((err_msg: string) => {
        alert(err_msg);
      }).finally(() => {
        set_loading_report_users(false);
      });
    }
  }
  const updateEditUser = () => {
    if (edit_user.email !== '') {
      set_loading_report_users(true);
      reportService.updateEditUser(edit_user).then((_updated_user) => {
        set_report_users((_prev) => _prev.map((_user) => _user.id === _updated_user.id ? _updated_user : _user));
      }).catch((err_msg: string) => {
        alert(err_msg);
      }).finally(() => {
        set_loading_report_users(false);
      });
    }
  }
  const deleteUser = (id: number) => {
    const yes = confirm(`Are you sure to delete ${id} report user?`);
    if (yes) {
      set_loading_report_users(true);
      reportService.deleteUser(id).then((_deleted) => {
        set_report_users((_users) => _users.filter((_user) => _user.id !== _deleted.id))
      }).catch((err_msg: string) => {
        alert(err_msg);
      })
    }
  }
  const sendReport = () => {
    const email = prompt('What is the email address?');
    if (email) {
      reportService.sendReport(email).then((result) => {
        alert('Successfully sent')
      }).catch((err_msg: string) => {
        alert(err_msg);
      })
    }
  }
  const sendCustomReport = () => {
    set_loading_custom_report(true);
    reportService.sendCustomReport(custom_report_from, custom_report_to, custom_report_email).then(() => {
      alert('Successfully sent');
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_loading_custom_report(false);
    })
  }
  useEffect(() => {
    reportService.getDailyReportEmails().then((_users) => {
      set_report_users(_users);
    }).catch((err_msg: string) => {
      alert(err_msg);
    });
  }, []);
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-end gap-4">
        <button className="px-4 py-2 rounded-md bg-green text-white" onClick={() => set_open_custom_report(true)}>Send Custom Report</button>
        <button className="px-4 py-2 rounded-md bg-green text-white" onClick={sendReport}>Send Report</button>
        <button className="px-4 py-2 rounded-md bg-blue-300 text-white" onClick={() => set_open_add_new_email(true)}>Add New Email</button>
      </div>
      <div className="grid grid-cols-5 gap-6 text-white border-b border-white">
        <div>ID</div>
        <div>Email</div>
        <div>Report Type</div>
        <div>Enabled</div>
      </div>
      {report_users.map((user) => (
        <div className="grid grid-cols-5 gap-6 text-white">
          <div>{user.id}</div>
          <div>{user.email}</div>
          <div>{user.report_type}</div>
          <div>{user.enable ? 'Enabled' : 'Disabled'}</div>
          <div className="flex items-center gap-4">
            <button onClick={() => {
              set_edit_user(user);
              set_open_edit_user(true);
            }}>Edit</button>
            <button onClick={() => {
              deleteUser(user.id)
            }}>Delete</button>
          </div>
        </div>
      ))}
      <Modal open={open_add_new_email} onClose={() => set_open_add_new_email(false)} center>
        <h2>Add New Email</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Email" title="Required email of new user">Email(*)</label>
          <input className="grow shrink border p-2" type="email" id="New Email" value={new_user.email} onChange={(e) => set_new_user((_prev) => ({ ..._prev, email: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Report Type" title="Required report type of new user">Report Type(*)</label>
          <select className="grow shrink border p-2" value={new_user.report_type} onChange={(e) => set_new_user((_prev) => ({ ..._prev, report_type: e.target.value }))}>
            {['08:00', '09:00'].map((report_type, id) => <option key={id} value={report_type}>{report_type}</option>)}
          </select>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_add_new_email(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_report_users} onClick={registerNewEmail}>{loading_report_users ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
      <Modal open={open_edit_user} onClose={() => set_open_edit_user(false)} center>
        <h2>Edit Report User({edit_user.id})</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Email" title="Required email of edit user">Email(*)</label>
          <input className="grow shrink border p-2" type="email" id="Edit Email" value={edit_user.email} onChange={(e) => set_edit_user((_prev) => ({ ..._prev, email: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Report Type" title="Required report type of new user">Report Type(*)</label>
          <select id="Edit Report Type" className="grow shrink border p-2" value={edit_user.report_type} onChange={(e) => set_edit_user((_prev) => ({ ..._prev, report_type: e.target.value }))}>
            {['08:00', '09:00'].map((report_type, id) => <option key={id} value={report_type}>{report_type}</option>)}
          </select>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <input className="ml-44 border p-2" type="checkbox" id="Edit Enabled" checked={edit_user.enable} onChange={(e) => set_edit_user((_prev) => ({ ..._prev, enable: e.target.checked }))} />
          <label className="w-40 text-black" htmlFor="Edit Enabled">{edit_user.enable ? 'Enabled' : 'Disabled'}</label>
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_user(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_report_users} onClick={updateEditUser}>{loading_report_users ? 'Updating' : 'Update'}</button>
        </div>
      </Modal>
      <Modal open={open_custom_report} onClose={() => set_open_custom_report(false)} center>
        <h2>Custom Report</h2>
        <hr />
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Custom Report Email" title="Required email of custom report">Email(*)</label>
          <input className="grow shrink border p-2" type="email" id="Custom Report Email" value={custom_report_email} onChange={(e) => set_custom_report_email(e.target.value)} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Custom Report From" title="Required from of custom report">From(*)</label>
          <input className="grow shrink border p-2" type="date" id="Custom Report From" value={custom_report_from} onChange={(e) => set_custom_report_from(e.target.value)} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Custom Report To" title="Required to of custom report">To(*)</label>
          <input className="grow shrink border p-2" type="date" id="Custom Report To" value={custom_report_to} onChange={(e) => set_custom_report_to(e.target.value)} />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_custom_report(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_custom_report} onClick={sendCustomReport}>{loading_custom_report ? 'Sending' : 'Send'}</button>
        </div>
      </Modal>
    </div>
  )
}