import axios, { AxiosError } from "axios";
import { API_BACKEND_ENDPOINT } from "../config";
import { DailyHistoryWithUser, EarningHistoryWithUser } from "@clc-v2/utilities";
import { V1Earning } from "./v1.service";

class HistoryService {
  getEarningHistoryRank(from: string, to: string, email: string) {
    return new Promise<Array<EarningHistoryWithUser>>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/history/earning-history-rank?from=${from}&to=${to}&email=${email}`).then((res: { data: Array<EarningHistoryWithUser> }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
  getDailyHistoryRank(from: string, to: string, email: string) {
    return new Promise<Array<DailyHistoryWithUser>>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/history/daily-history-rank?from=${from}&to=${to}&email=${email}`).then((res: { data: Array<DailyHistoryWithUser> }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
  updateEarningHistory(user_id: string, earnings: V1Earning[]) {
    return new Promise<{ count: number }>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/history/update-earning-history-from-v1`, {
        user_id, earnings
      }).then((res: { data: { count: number } }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      })
    })
  }
}

export const historyService = new HistoryService();
