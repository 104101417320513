import axios, { AxiosError } from "axios"
import { API_BACKEND_ENDPOINT } from "../config";
import { IEarningData, IUserDetail, IUserWithAccountsAndInvite, IUsersData } from "@clc-v2/utilities";
import { User } from "@prisma/client";

class UsersService {
    getAllUsers() {
        return new Promise<IUserWithAccountsAndInvite[]>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user`).then((res: {
                data: Array<IUserWithAccountsAndInvite>
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    getUserDetail(user_id: string) {
        return new Promise<IUserDetail>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/user/${user_id}/detail`).then((res: {
                data: IUserDetail
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    blockUser(user_id: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/${user_id}/block`).then((res: {
                data: boolean
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    unblockUser(user_id: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/${user_id}/un-block`).then((res: {
                data: boolean
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    updateUser(user: User) {
        return new Promise<User>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/user/${user.id}`, user).then((res: {
                data: User
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    uploadUsersCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/user/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    /**
     * @deprecated
     * because firebase data is not correct, so will use data of v1 database
     */
    syncUserEarningData(earning_data: IEarningData, user_id: string) {
        return new Promise<User>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/sync-earning-from-firebase`, {
                ...earning_data,
                user_id: user_id,
            }).then((res: {
                data: User
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    /**
     * @deprecated
     * because firebase data is not correct, so will use data of v1 database
     */
    syncUserData(user_data: IUsersData, user_id: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/sync-data-from-firebase`, {
                ...user_data,
                user_id: user_id,
            }).then((res: {
                data: boolean
            }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    updateCoupon(user_id: string, coupon_code: string) {
        return new Promise<User>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-coupon-code`, {
                user_id,
                coupon_code,
            }).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    updateReferer(user_id: string, referer_email: string) {
        return new Promise<User>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/update-referer`, {
                user_id,
                referer_email,
            }).then((res: { data: User }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    resetChips(user_id: string) {
        return new Promise<{ success: boolean }>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/reset-chips?user_id=${user_id}`).then((res: { data: { success: boolean } }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    resetAllUsersChips() {
        return new Promise<{ success: boolean }>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/user/reset-all-users-chips`).then((res: { data: { success: boolean } }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const usersService = new UsersService();
