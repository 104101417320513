import { Glossary, Lesson, Topic } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'

export interface TopicLessonQuestionState {
  topics?: Topic[];
  lessons?: Lesson[];
  // questions?: Question[];
  glossaries?: Glossary[];
}

const defaultState: TopicLessonQuestionState = {
}

export const topicLessonQuestionSlice = createSlice({
  name: 'topic-lesson-question',
  initialState: defaultState,
  reducers: {
    set_topics: (state: TopicLessonQuestionState, action: { payload: Topic[] }) => {
      state.topics = action.payload;
    },
    set_lessons: (state: TopicLessonQuestionState, action: { payload: Lesson[] }) => {
      state.lessons = action.payload;
    },
    // set_questions: (state: TopicLessonQuestionState, action: { payload: Question[] }) => {
    //   state.questions = action.payload;
    // },
    set_glossaries: (state: TopicLessonQuestionState, action: { payload: Glossary[] }) => {
      state.glossaries = action.payload;
    },
    clear_topic_lesson_question: (state: TopicLessonQuestionState) => {
      state = defaultState;
    }
  },
})

export const {
  set_topics,
  set_lessons,
  set_glossaries,
  clear_topic_lesson_question,
} = topicLessonQuestionSlice.actions
export const topicLessonQuestionReducer = topicLessonQuestionSlice.reducer